export const environment = {
  stage: 'dev',
  appTitle: 'Buy & Binge, E-store-One Stop For Everything',
  serverUrl: 'https://dev.ecomm.yashilabs.com/',
  apiUrl: 'https://dev.api.ecommerce.yashilabs.com/',
  production: false,
  s3Config: {
    region: 'ap-south-1',
    credentials: {
      accessKeyId: 'AKIA2ADY6YFMHG6RUCUA',
      secretAccessKey: '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',
      signatureVersion: 'v4',
    },
  },
  s3Bucket: 'yashi-labs',
  s3Object: 'ecommerce-app/files',
  s3Url: 'https://yashi-labs.s3.ap-south-1.amazonaws.com/',
};
