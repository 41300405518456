import { Component, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { Router, RouterLink } from '@angular/router';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-menu-dialog',
  standalone: true,
  imports: [CommonModule,
    MatIconModule,
    MatListModule,
    RouterLink,
    MatDialogModule
  ],
  templateUrl: './menu-dialog.component.html',
  styles: [
  ]
})
export class MenuDialogComponent {
  token: any;
  openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }
  constructor(private dialog: MatDialog,
    private router: Router,
    private auth: AuthService) {
    this.token = this.auth.token;
  }

  logout() {
    localStorage.removeItem('access_token');
    this.router.navigate(['/login']);
  }
}
