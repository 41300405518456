import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { Category } from 'src/product.model';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FilterDTO, PageDTO } from './app.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryService extends AbstractService<Category> {
  override serviceName(): string {
    return 'categories';
  }

  public override filterData(
    filter: FilterDTO,
    headers?: HttpHeaders
  ): Observable<PageDTO<Category>> {
    headers = new HttpHeaders()
      .set('skip', 'true')
      .set('Content-Type', 'application/json');
    return super.filterData(filter, headers);
  }
}
