import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
  RouterModule,
} from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, map, shareReplay } from 'rxjs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatBadgeModule } from '@angular/material/badge';

import { MatToolbarModule } from '@angular/material/toolbar';
import { db } from 'src/db';
import { FilterDTO, User } from '../app.model';
import { Category, Product } from 'src/product.model';
import { CategoryService } from '../category.service';
import { MatListModule } from '@angular/material/list';
import { FormsModule } from '@angular/forms';
import { UsernameInTokenPipe } from '../username-in-token.pipe';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AuthService } from '../auth.service';
import { MenuDialogComponent } from '../menu-dialog/menu-dialog.component';
import { categories } from '../categories';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppendS3Pipe } from '../append-s3.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    RouterLink,
    MatSidenavModule,
    MatToolbarModule,
    MatBadgeModule,
    MatListModule,
    FormsModule,
    UsernameInTokenPipe,
    MatDialogModule,
    MatTooltipModule,
    AppendS3Pipe,
    MatMenuModule,
    MatButtonModule,
  ],
  templateUrl: './header.component.html',
  styles: [
    `
      .example-container {
        width: 148px;
        z-index: 10;
      }

      .example-sidenav-content {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      }

      .example-sidenav {
        width: 100%;
        height: 153px;
        z-index: 10;
        background: #c44055;
        color: #f1f1f1;
      }

      .mat-badge.mat-badge {
        color: #f9e4e7;
      }

      .mat-badge-medium.mat-badge-above .mat-badge-content {
        color: #f9e4e7 !important;
      }
    `,
  ],
})
export class HeaderComponent implements OnInit {
  openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }
  categories = categories;
  count$: Observable<number>;
  token: any;
  categories$: Observable<Category[]>;
  parentCategories = [
    'SOFTWARE PRODUCTS',
    'STREAMING SERVICES',
    'EDUCATIONAL TOOLS',
    'GIFT CARDS',
    'AI TOOLS',
  ];
  hideSidebar = false;
  navArray: Set<string> = new Set();
  user$: Observable<User> = this.auth.getProfile();
  isHandset$: Observable<boolean>;
  constructor(
    private dialog: MatDialog,
    public router: Router,
    private categoryService: CategoryService,
    private breakpointObserver: BreakpointObserver,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private auth: AuthService
  ) {
    if (this.auth.isAuthenticated()) {
      this.token = this.auth.token;
    }
  }
  getShortName(fullName: string) {
    return fullName
      .split(' ')
      .map(n => n[0])
      .join('');
  }
  category: string = '';
  public show: boolean = false;
  public buttonName: any = 'Show';

  goBack() {
    this.location.back();
  }
  toggle() {
    this.show = !this.show;

    // Change the name of the button.
    if (this.show) this.buttonName = 'Hide';
    else this.buttonName = 'Show';
  }
  mobileMenu() {
    this.dialog.open(MenuDialogComponent);
  }
  close() {
    this.show = !this.show;

    // Change the name of the button.
    if (this.show) this.buttonName = 'Hide';
    else this.buttonName = 'Show';
  }
  @Output() closeSideNav = new EventEmitter();
  ngOnInit(): void {
    this.count$ = db.countAll();
    this.isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(result => result.matches),
      shareReplay()
    );

    this.navArray.add('software-products');
    this.navArray.add('streaming-services');
    this.navArray.add('education-and-learning');
    this.navArray.add('gift-cards');
    this.navArray.add('ai-tools');
    this.activatedRoute.data.subscribe(res => console.log(res));
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const urls = event.url.split('/');
        const url = urls[2];

        if (this.navArray.has(url)) {
          this.category = url;
          console.log(this.category);
          this.hideSidebar = false;
        } else if (url == 'login' || url == 'signup') {
          this.hideSidebar = true;
        }
      }
    });
  }
  onToggleClose() {
    this.closeSideNav.emit();
  }
  hover(category: string, isMouseHover: boolean) {
    this.isHidden = !isMouseHover;

    const filter: FilterDTO = {
      pageNumber: 0,
      pageSize: 10,
      search: `parentCategory.name:${category}`,
    };
    this.categories$ = this.categoryService
      .filterData(filter)
      .pipe(map(page => page.elements));
  }
  isHidden = false;
  toggleTheme() {}

  toggleLang() {}
  @ViewChild('productInput') searchInput: ElementRef;
  @ViewChild('input') input: ElementRef;
  @ViewChild('product') prod: ElementRef;
  search(search: string, prod?: Product) {
    this.router.navigate(['/products'], {
      queryParams: {
        search: search,
        category: prod?.category ?? '',
        subCategory: prod?.subCategory ?? '',
        brand: prod?.brand ?? '',
      },
    });
    this.searchInput.nativeElement.value = '';
    this.input.nativeElement.value = '';
    this.prod.nativeElement.value = '';
  }
  logout() {
    localStorage.removeItem('access_token');
    this.router.navigate(['/login']);
  }
}
