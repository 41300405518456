<swiper-container #swiper [pagination]="true" pagination-clickable="true">
  <ng-container *ngIf="getImg | async as image">
    <swiper-slide *ngFor="let img of image; let i = index">
      <!-- <div class="w-full mt-0 h-[175px] xs:h-[190px] sm:h-[200px] md:h-[250px] relative lg:h-[280px] flex
                         rounded-[15px] gap-x-[15px] lg:gap-x-[10px]">

                <img src="../../assets/images/google-img.jpg" class="object-fill w-full rounded-[15px]" alt="">
                <a class="no-underline absolute left-[7%] bottom-[15%]" routerLink="/product-details/{{img.productId}}">
                    <span
                        class="w-fit text-white text-[14px] xs:text-[25px] lg:text-[32px] leading-[48px]
                                                                            font-medium py-1 px-4 xs:px-8 bg-[#ff4a5f] rounded-[15px]">
                        Buy Now</span>
                </a>
            </div> -->
      <!-- class="w-full mt-0 h-[150px] xs:h-[240px] lg:h-[280px] flex pl-[40px] lg:pl-[50px] pr-[15px] rounded-[15px] gap-x-[15px]" -->
      <a
        class="w-full mt-0 h-[150px] xs:h-[240px] lg:h-[280px] flex pr-[15px] rounded-[15px] bg-repeat-round"
        [style.background]="'url(' + img.image + ')'"
        [style.backgroundRepeat]="'round'"
        routerLink="/product-details/{{ img.productId }}">
        <!-- class="flex flex-col gap-y-[15px] xs:gap-y-[20px] lg:gap-y-[22px] xl:gap-y-[30px] text-white w-[50%] m-auto justify-start"> -->
        <div
          class="flex flex-col mb-3 text-white justify-end px-8 pl-[40px] md:pl-[80px] pt-10 md:pt-40">
          <!-- <a
            class="no-underline"
            routerLink="/product-details/{{ img.productId }}">
            <h1
              class="text-[22px] xs:text-[25px] md:text-[30px] text-white md:text-[35px] overflow-hidden line-clamp-2 xs:line-clamp-1 !mb-0 font-[600] lg:text-[40px]">
              {{ img.description }}
            </h1>
          </a>
          <p
            class="text-[16px] md:text-[18px] !hidden !mb-0 lg:text-[22px] font-medium xs:!line-clamp-2 md:!line-clamp-1"
            [innerHTML]="img.description"></p> -->
          <!-- <div class="flex justify-center"> -->
          <!-- <a
            class="no-underline flex justify-start"
            routerLink="/product-details/{{ img.productId }}">
            <span
              class="w-fit text-white text-[14px] xs:text-[20px] md:text-[25px] lg:text-[32px] leading-[26px] xs:leading-[35px] lg:leading-[48px] font-medium px-[1rem] xs:px-8 py-2 bg-[#ff4a5f] rounded-[15px]">
              Buy Now</span
            >
          </a> -->
          <!-- </div> -->
        </div>
        <!-- <div
          class="m-0 flex justify-center py-0 sm:justify-end w-[50%] h-[150px] xs:h-[240px] lg:h-[280px] sm:pr-0">
          <img
            src="{{ img.image }}"
            class="object-cover m-auto xs:my-auto h-[100px] xs:h-[80%] lg:h-[76%] xl:h-[80%] w-full rounded-[15px]"
            alt="product" />
        </div> -->
      </a>
    </swiper-slide>
  </ng-container>
</swiper-container>
<!-- <a
            class="no-underline flex justify-start"
            routerLink="/product-details/{{ img.productId }}">
            <span
              class="w-fit text-white text-[14px] xs:text-[20px] md:text-[25px] lg:text-[32px] leading-[26px] xs:leading-[35px] lg:leading-[48px] font-medium px-[1rem] xs:px-8 py-2 bg-[#ff4a5f] rounded-[15px]">
              Buy Now</span
            >
          </a>
      
        </div> -->
