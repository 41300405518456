export const categories = [
  {
    name: 'SOFTWARE PRODUCTS',
    subCategories: ['Graphic Design', 'Office Apps', 'Cloud Storage'],
    expanded: false,
  },

  {
    name: 'STREAMING SERVICES',
    subCategories: ['Coming Soon'],
    expanded: false,
  },
  {
    name: 'EDUCATIONAL TOOLS',
    subCategories: ['Writing Tools', 'Online Learning Platforms'],
    expanded: false,
  },

  {
    name: 'GIFT CARDS',
    subCategories: ['Itunes', 'Netflix'],
    expanded: false,
  },
  {
    name: 'AI TOOLS',
    subCategories: [
      'Code Assistant',
      'Conversational And Writing Assistant',
      'Image And Video Ai',
      'AI for Business',
    ],
    expanded: false,
  },
];
