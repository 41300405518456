<div
  class="flex flex-col"
  *ngIf="productByCategories$ | async as pc; else loading">
  <div class="pb-[15px] pt-[30px] hidden md:flex gap-2 md:justify-center">
    <hr
      class="max-w-[1366px] w-full border-[1px] border-solid border-[#c44055] absolute" />

    <p
      class="text-[16px] 2xl:text-[18px] leading-[27px] font-[600] w-fit text-center relative z-[5] top-[38%] left-0 px-[8px] bg-[#f8f6f0]">
      Categories That Shine: Find Your Perfect Pick Here
    </p>
  </div>
  <!-- <div
    class="pb-[20px] pt-[30px] hidden md:flex gap-2 md:justify-center relative">
    <hr
      class="border-[1px] border-solid border-[#c44055] absolute left-0 right-0" />
    <p
      class="text-[16px] 2xl:text-[18px] leading-[27px] font-[600] text-center relative z-[5] bg-[#f1f1f1]">
      Categories That Shine: Find Your Perfect Pick Here
    </p>
    <hr
      class="border-[1px] border-solid border-[#c44055] absolute left-0 right-0" />
  </div> -->
  <div
    class="flex flex-col md:flex-row pt-[20px] md:py-[20px] gap-y-[10px] lg:justify-between lg:h-auto md:gap-x-[10px] lg:gap-x-[8px] bg-[#f1f1f1]">
    <div
      class="flex flex-row h-[200px] md:h-[660px] md:!flex-col lg:!flex-row justify-between gap-x-[8px] lg:h-auto md:w-[50%] lg:w-[40%] md:gap-y-[10px] lg:gap-y-0 lg:gap-x-[8px]">
      <!-- Electronic Gadgets starts here -->
      <div
        class="w-[50%] md:w-auto h-[200px] md:h-[255px] flex md:flex-col lg:w-[50%] md:h-full lg:h-auto relative lg:gap-y-[8px] bg-white p-[8px] rounded-[15px]">
        <span
          class="hidden md:block text-center text-black font-medium text-[18px] leading-[27px] md:h-[6%] lg:h-[7%]">
          <a
            [routerLink]="['/products']"
            [queryParams]="{ category: 'SOFTWARE PRODUCTS' }"
            class="no-underline text-black">
            Software Products</a
          ></span
        >
        <div
          class="flex flex-col gap-y-[8px] md:flex-row lg:flex-col md:gap-x-[10px] md:h-[94%] lg:h-[93%] lg:gap-x-0 w-full md:w-full">
          <div
            class="w-full h-full block md:flex md:flex-col md:h-full lg:flex-row md:w-[50%] md:gap-[8px] lg:h-auto lg:w-full !max-h-[200px] md:!max-h-none md:pt-3">
            <!-- mobile  view starts here -->
            <a
              routerLink="/category"
              [queryParams]="{ category: 'SOFTWARE PRODUCTS' }"
              class="block md:hidden h-full">
              <div
                class="w-full relative grid grid-cols-1 grid-rows-1 h-full md:hidden md:basis-full md:m-auto lg:m-0 md:p-[6px] lg:h-[150px] md:bg-[#efe4ff] md:rounded-[15px]">
                <img
                  src="../../assets/images/home/products/gift-cards.png"
                  alt=""
                  class="object-cover h-full md:h-[100px] lg:h-[70%] w-full md:w-auto rounded-[15px] md:rounded-none md:!mx-auto" />
                <p
                  class="absolute w-full md:hidden overflow-hidden h-auto !mb-0 line-clamp-2 !pb-0 text-white bottom-[10%] text-center text-[12px] xs:text-[14px] font-[600] lg:h-[30%] leading-[21px]">
                  SOFTWARE PRODUCTS
                </p>
              </div>
            </a>
            <!-- mobile view ends here -->

            <!-- tab and desktop view starts here -->
            <ng-container
              *ngFor="
                let product of $any(pc)['SOFTWARE PRODUCTS'];
                let i = index
              "
              [class]="i > 1 ? 'flex' : 'hidden md:!flex md:flex-col'">
              <div
                class="w-full hidden md:flex md:flex-col md:my-auto md:h-[210px] lg:w-[50%] justify-around md:m-0 md:h-[50%] lg:m-0 md:p-[6px] lg:h-[150px] md:bg-[#efe4ff] lg:rounded-[15px]"
                *ngIf="i < 2">
                <a
                  routerLink="/{{ product.category }}/{{ product.id }}"
                  class="hidden md:block m-auto">
                  <img
                    src="{{product.images?.[0]?.key | appendS3}}"
                    alt=""
                    class="object-contain h-full md:h-[190px] lg:h-[100px] lg:w-[74px] 2xl:w-[85px] w-full md:w-[150px] rounded-[15px] md:rounded-none md:!mx-auto" />
                </a>
                <p
                  class="absolute md:hidden !pb-0 text-white bottom-[10%] left-[50%] text-[14px] font-[600] lg:h-[30%] leading-[21px]">
                  Gift Cards
                </p>
                <a
                  routerLink="/{{ product.category }}/{{ product.id }}"
                  class="hidden md:block no-underline text-black h-[16%] lg:h-[24%] items-center self-center w-full">
                  <p
                    class="!mb-0 line-clamp-2 h-auto overflow-hidden !pb-0 text-[12px] font-medium md:pt-[5px] text-center h-[29%] leading-[16px] w-full">
                    {{ product.name }}
                  </p>
                </a>
              </div>
            </ng-container>
          </div>
          <ng-container
            *ngIf="$any(pc)['SOFTWARE PRODUCTS']; else noElectronicGadgets">
            <!-- Tablet View Starts here -->
            <div
              class="w-full hidden md:flex md:flex-col lg:hidden md:gap-x-[8px] md:gap-y-[10px] lg:gap-y-0 md:w-[50%]">
              <ng-container
                *ngFor="
                  let product of $any(pc)['SOFTWARE PRODUCTS'];
                  let i = index
                "
                [class]="i > 1 ? 'hidden md:flex ' : 'flex flex-col'">
                <div
                  class="w-full md:flex md:flex-col md:basis-full md:m-auto justify-around md:p-[6px] md:bg-[#efe4ff]"
                  *ngIf="i > 0 && i < 4">
                  <a
                    routerLink="/{{ product.category }}/{{ product.id }}"
                    class="hidden md:block lg:m-auto">
                    <img
                      src="{{product.images?.[0]?.key | appendS3}}"
                      alt=""
                      class="object-contain h-full md:h-[135px] lg:h-[100px] lg:w-[74px] 2xl:w-[85px] w-full md:w-[145px] rounded-[15px] md:rounded-none md:m-auto" />
                  </a>
                  <p
                    class="absolute md:hidden !pb-0 text-white bottom-[10%] left-[50%] text-[14px] font-[600] lg:h-[30%] leading-[21px]">
                    Gift Cards
                  </p>
                  <a
                    routerLink="/{{ product.category }}/{{ product.id }}"
                    class="hidden md:flex items-center self-center no-underline text-black h-[24%] self-center">
                    <p
                      class="h-auto !mb-0 line-clamp-2 overflow-hidden !pb-0 text-[12px] font-medium md:pt-[5px] text-center h-[29%] leading-[16px] w-full">
                      {{ product.name }}
                    </p>
                  </a>
                </div>
              </ng-container>
            </div>
            <!-- Tablet View Ends here -->
            <div
              class="w-full hidden lg:!flex lg:flex-row md:gap-x-[8px] md:gap-y-[10px] lg:gap-y-0 md:w-[50%] lg:h-auto lg:w-full">
              <ng-container
                *ngFor="
                  let product of $any(pc)['SOFTWARE PRODUCTS'];
                  let i = index
                "
                [class]="i > 1 ? 'hidden md:flex ' : 'flex flex-col'">
                <div
                  class="w-full md:flex md:flex-col md:m-auto lg:w-[50%] lg:m-0 justify-around md:p-[6px] lg:h-[150px] md:bg-[#efe4ff] md:rounded-[15px]"
                  *ngIf="i > 1 && i < 4">
                  <a
                    routerLink="/{{ product.category }}/{{ product.id }}"
                    class="hidden md:block m-auto">
                    <img
                      src="{{product.images?.[0]?.key | appendS3}}"
                      alt=""
                      class="object-contain h-full md:h-[100px] lg:h-[100px] lg:w-[74px] 2xl:w-[85px] w-full md:w-auto rounded-[15px] md:rounded-none md:m-auto" />
                  </a>
                  <p
                    class="absolute md:hidden !pb-0 text-white bottom-[10%] left-[50%] text-[14px] font-[600] lg:h-[30%] leading-[21px]">
                    Gift Cards
                  </p>
                  <a
                    routerLink="/{{ product.category }}/{{ product.id }}"
                    class="hidden md:flex no-underline text-black h-[24%] items-center self-center w-full">
                    <p
                      class="h-auto !mb-0 line-clamp-2 whitespace-normal overflow-hidden !pb-0 text-[12px] font-medium md:pt-[5px] text-center h-[29%] leading-[16px] w-full">
                      {{ product.name }}
                    </p>
                  </a>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <!-- tap and desktop view ends here -->
          <ng-template #noElectronicGadgets>
            <span class="text-[#C40055] hidden md:block text-center font-medium"
              >No Products</span
            >
          </ng-template>
        </div>
      </div>
      <!-- Electronic Gadgets Ends Here -->

      <!-- Subscriptions Starts here -->
      <div
        class="w-[50%] md:w-auto h-[200px] md:hidden md:h-[255px] flex lg:flex lg:flex-col lg:w-[50%] md:basis-[50%] md:h-[50%] lg:h-auto relative lg:gap-y-[8px] bg-white p-[8px] rounded-[15px]">
        <span
          class="hidden md:block text-center text-black font-medium text-[18px] leading-[27px]">
          <a
            [routerLink]="['/products']"
            [queryParams]="{ category: 'STREAMING SERVICES' }"
            class="no-underline text-black">
            Streaming Services
          </a></span
        >

        <div class="flex flex-col gap-y-[8px] w-full md:w-auto md:h-[90%]">
          <div
            class="w-full h-full block md:flex md:gap-[8px] md:h-[75%] lg:h-auto lg:w-full !max-h-[200px]">
            <!-- mobile  view starts here -->
            <a
              routerLink="/category"
              [queryParams]="{ category: 'STREAMING SERVICES' }"
              class="block h-full md:!hidden">
              <div
                class="w-full relative grid grid-cols-1 grid-rows-1 h-full md:hidden md:basis-full md:m-auto lg:m-0 md:p-[6px] lg:h-[150px] md:bg-[#efe4ff] md:rounded-[15px]">
                <img
                  src="../../assets/images/home/products/games.png"
                  alt=""
                  class="object-cover h-full md:h-[100px] lg:h-[70%] w-full md:w-auto rounded-[15px] md:rounded-none md:!mx-auto" />
                <p
                  class="absolute w-full md:hidden overflow-hidden h-auto !mb-0 line-clamp-2 !pb-0 text-white bottom-[10%] text-center text-[12px] xs:text-[14px] font-[600] lg:h-[30%] leading-[21px]">
                  STREAMING SERVICES
                </p>
              </div>
            </a>
            <!-- mobile view ends here -->

            <!-- tab and desktop view starts here -->
            <ng-container
              *ngFor="
                let product of $any(pc)['STREAMING SERVICES'];
                let i = index
              "
              [class]="i > 1 ? 'flex' : 'hidden md:!flex md:flex-col'">
              <div
                class="w-full hidden md:flex md:flex-col md:h-[210px] md:basis-full justify-around md:m-auto lg:m-0 md:p-[6px] lg:w-[50%] lg:h-[150px] md:bg-[#efe4ff] md:rounded-[15px]"
                *ngIf="i < 2">
                <a
                  routerLink="/{{ product.category }}/{{ product.id }}"
                  class="hidden md:block m-auto">
                  <img
                    src="{{product.images?.[0]?.key | appendS3}}"
                    alt=""
                    alt=""
                    class="object-contain h-full md:h-[100px] lg:h-[100px] lg:w-[74px] 2xl:w-[85px] w-full md:w-auto rounded-[15px] md:rounded-none md:!mx-auto" />
                </a>
                <p
                  class="absolute md:hidden !pb-0 text-white bottom-[10%] left-[50%] text-[14px] font-[600] lg:h-[30%] leading-[21px]">
                  Gift Cards
                </p>
                <a
                  routerLink="/{{ product.category }}/{{ product.id }}"
                  class="hidden md:flex no-underline text-black h-[24%] w-full items-center self-center w-full">
                  <p
                    class="h-auto !mb-0 line-clamp-2 overflow-hidden !pb-0 text-[12px] font-medium md:pt-[5px] text-center h-[29%] leading-[16px] w-full">
                    {{ product.name }}
                  </p>
                </a>
              </div>
            </ng-container>
          </div>
          <ng-container
            *ngIf="$any(pc)['STREAMING SERVICES']; else noSubscription">
            <div
              class="w-full hidden lg:flex md:gap-x-[8px] md:h-[75%] lg:h-auto lg:w-auto">
              <ng-container
                *ngFor="
                  let product of $any(pc)['STREAMING SERVICES'];
                  let i = index
                "
                [class]="i > 1 ? 'hidden md:flex ' : 'flex flex-col'">
                <div
                  class="w-full md:basis-full md:flex md:flex-col md:m-auto lg:m-0 justify-around md:p-[6px] lg:w-[50%] lg:h-[150px] md:bg-[#efe4ff] md:rounded-[15px]"
                  *ngIf="i > 1 && i < 4">
                  <a
                    routerLink="/{{ product.category }}/{{ product.id }}"
                    class="hidden md:block m-auto">
                    <img
                      src="{{product.images?.[0]?.key | appendS3}}"
                      alt=""
                      alt=""
                      class="object-contain h-full md:h-[100px] lg:h-[100px] lg:w-[74px] 2xl:w-[85px] w-full md:w-auto rounded-[15px] md:rounded-none md:m-auto" />
                  </a>
                  <p
                    class="absolute md:hidden !pb-0 text-white bottom-[10%] left-[50%] text-[14px] font-[600] lg:h-[30%] leading-[21px]">
                    Gift Cards
                  </p>
                  <a
                    routerLink="/{{ product.category }}/{{ product.id }}"
                    class="hidden md:flex no-underline text-black h-[24%] items-center self-center w-full">
                    <p
                      class="h-auto !mb-0 line-clamp-2 overflow-hidden !pb-0 text-[12px] font-medium md:pt-[5px] text-center h-[29%] leading-[16px] w-full">
                      {{ product.name }}
                    </p>
                  </a>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <!-- tap and desktop view ends here -->
          <ng-template #noSubscription>
            <span class="text-[#C44055] hidden md:block font-medium text-center"
              >No Products</span
            >
          </ng-template>
        </div>
      </div>
      <!-- Subscriptions ends Here -->
    </div>
    <div
      class="flex flex-col lg:flex-row pb-[15px] md:pb-0 md:w-[50%] md:h-[660px] gap-x-[8px] lg:h-auto lg:w-[60%] gap-y-[10px] lg:gap-y-0 md:gap-x-[8px]">
      <!-- Software Keys Starts Here -->
      <div
        class="rounded-[15px] relative md:gap-y-[8px] flex md:h-[33%] lg:h-auto lg:pb-0 md:flex-col w-full lg:w-[33%] bg-white p-[8px] rounded-[15px]"
        style="overflow: hidden">
        <span
          class="hidden md:block text-center text-black font-medium text-[18px] leading-[27px]">
          <a
            [routerLink]="['/products']"
            [queryParams]="{ category: 'EDUCATIONAL TOOLS' }"
            class="no-underline text-black">
            Educational Tools</a
          >
        </span>
        <!-- mobile view starts here -->
        <div
          class="w-full block h-[220px] xs:h-[255px] md:hidden !max-h-[200px]">
          <a
            routerLink="/category"
            [queryParams]="{ category: 'EDUCATIONAL TOOLS' }">
            <div
              class="w-full relative grid grid-rows-1 grid-cols-1 md:basis-[50%] h-full md:p-[6px] md:rounded-[15px] md:bg-[#fff1e8] lg:h-[150px]">
              <img
                src="../../assets/images/home/products/electronic.png"
                alt=""
                class="object-cover w-full h-full md:w-auto md:h-[100px] md:m-auto rounded-[15px] md:rounded-none lg:h-[70%]" />
              <span
                class="absolute w-full md:hidden overflow-hidden text-white bottom-[10%] left-[50%] text-[12px] xs:text-[14px] font-[600] leading-[21px]">
                EDUCATIONAL TOOLS
              </span>
            </div>
          </a>
        </div>
        <!-- mobile view ends here -->

        <!-- Tablet and desktop view starts here -->
        <ng-container
          *ngIf="$any(pc)['EDUCATIONAL TOOLS']; else noSoftwareKeys">
          <div
            class="w-full hidden h-[220px] xs:h-[255px] md:flex md:gap-x-[8px] md:h-[85%] lg:h-auto">
            <ng-container
              *ngFor="
                let product of $any(pc)['EDUCATIONAL TOOLS'];
                let i = index
              "
              [class]="i > 1 ? 'flex' : 'hidden md:!flex md:flex-col'">
              <div
                class="w-full md:basis-[50%] md:flex md:flex-col md:p-[6px] justify-around md:rounded-[15px] lg:w-[50%] md:bg-[#fff1e8] lg:h-[150px]"
                *ngIf="i < 2">
                <a
                  routerLink="/{{ product.category }}/{{ product.id }}"
                  class="hidden md:block m-auto">
                  <img
                    src="{{product.images?.[0]?.key | appendS3}}"
                    alt=""
                    alt=""
                    class="object-contain w-full h-full md:w-[120px] md:h-[120px] md:m-auto rounded-[15px] md:rounded-none lg:h-[100px] lg:w-[74px] 2xl:w-[85px]" />
                </a>
                <span
                  class="absolute md:hidden text-white bottom-[10%] left-[50%] text-[14px] font-[600] leading-[21px]">
                </span>
                <a
                  routerLink="/{{ product.category }}/{{ product.id }}"
                  class="hidden md:flex no-underline text-black h-[24%] items-center self-center w-full">
                  <p
                    class="md:pt-[5px] h-auto overflow-hidden w-full !mb-0 text-[12px] line-clamp-2 font-medium text-center h-[29%] leading-[16px] lg:h-[30%] w-full">
                    {{ product.name }}
                  </p>
                </a>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-template #noSoftwareKeys>
          <span class="text-[#C40055] hidden md:block text-center font-medium"
            >No Products</span
          >
        </ng-template>
        <div class="w-full hidden lg:flex lg:gap-x-[8px]">
          <ng-container
            *ngFor="let product of $any(pc)['EDUCATIONAL TOOLS']; let i = index"
            [class]="i > 1 ? 'flex' : 'hidden lg:flex lg:flex-col'">
            <div
              class="lg:w-[50%] md:flex md:flex-col md:p-[6px] justify-around md:rounded-[15px] md:bg-[#fff1e8] lg:h-[150px]"
              *ngIf="i > 1 && i < 4">
              <a
                routerLink="/{{ product.category }}/{{ product.id }}"
                class="hidden md:block m-auto">
                <img
                  src="{{product.images?.[0]?.key | appendS3}}"
                  alt=""
                  class="object-contain w-full h-full md:w-auto md:h-[100px] md:m-auto rounded-[15px] md:rounded-none lg:h-[100px] lg:w-[74px] 2xl:w-[85px]" />
              </a>
              <p
                class="absolute lg:hidden text-white bottom-[10%] left-[32%] text-[14px] font-[600] leading-[21px]">
                Gift Cards
              </p>
              <a
                routerLink="/{{ product.category }}/{{ product.id }}"
                class="hidden md:flex no-underline text-black h-[24%] items-center self-center w-full">
                <p
                  class="h-auto md:pt-[5px] w-full overflow-hidden !mb-0 line-clamp-2 text-[12px] font-medium text-center h-[29%] leading-[16px] lg:h-[30%] w-full">
                  {{ product.name }}
                </p>
              </a>
            </div>
          </ng-container>
        </div>
        <!-- Tablet and desktop view Ends here -->
      </div>
      <!-- Software Keys Ends Here -->
      <div
        class="flex md:flex-col lg:flex-row h-[200px] md:h-[67%] justify-between gap-x-[8px] md:h-[67%] lg:h-auto md:pb-0 lg:w-[66%] md:gap-y-[10px] lg:gap-y-0 md:gap-x-[8px]">
        <!-- Gift cards Starts Here -->
        <div
          class="w-[50%] md:w-auto h-[200px] md:h-[255px] md:basis-full md:h-[50%] lg:h-auto flex md:flex-col lg:w-[50%] relative md:gap-y-[8px] bg-white p-[8px] rounded-[15px]">
          <span
            class="hidden md:block text-center text-black font-medium text-[18px] leading-[27px]">
            <a
              [routerLink]="['/products']"
              [queryParams]="{ category: 'GIFT CARDS' }"
              class="no-underline text-black">
              Gift Cards</a
            >
          </span>
          <!-- Mobile View Starts Here -->
          <div class="w-full block md:hidden !max-h-[200px]">
            <a
              routerLink="/category"
              [queryParams]="{ category: 'GIFT CARDS' }">
              <div
                class="w-full relative h-full grid grid-rows-1 grid-cols-1 md:basis-[50%] rounded-[10px] md:p-[6px] md:rounded-[15px] md:bg-[#ffe7fa] lg:h-[150px]">
                <img
                  src="../../assets/images/home/products/microsoft.png"
                  alt=""
                  class="object-cover w-full h-full md:w-auto md:h-[100px] md:m-auto rounded-[10px] md:rounded-none lg:h-[70%]" />
                <p
                  class="absolute w-full md:hidden h-auto overflow-hidden !mb-0 line-clamp-2 text-white bottom-[10%] text-center text-[12px] xs:text-[14px] font-[600] leading-[21px]">
                  GIFT CARDS
                </p>
              </div>
            </a>
          </div>
          <!-- Mobile View Ends Here -->

          <!-- Tablet and Desktop View Starts Here -->
          <ng-container *ngIf="$any(pc)['GIFT CARDS']; else noGiftCards">
            <div
              class="w-full hidden md:flex md:gap-x-[8px] md:h-[85%] lg:h-auto">
              <ng-container
                *ngFor="let product of $any(pc)['GIFT CARDS']; let i = index"
                [class]="i > 1 ? 'flex' : 'hidden md:flex md:flex-col'">
                <div
                  class="w-full md:basis-[50%] md:flex md:flex-col lg:w-[50%] rounded-[10px] justify-around md:p-[6px] md:rounded-[15px] md:bg-[#ffe7fa] lg:h-[150px]"
                  *ngIf="i < 2">
                  <a
                    routerLink="/{{ product.category }}/{{ product.id }}"
                    class="hidden md:block m-auto">
                    <img
                      src="{{product.images?.[0]?.key | appendS3}}"
                      alt=""
                      class="object-contain w-full h-full md:w-[120px] md:h-[120px] md:m-auto rounded-[10px] md:rounded-none lg:h-[100px] lg:w-[74px] 2xl:w-[85px]" />
                  </a>
                  <p
                    class="absolute md:hidden text-white bottom-[10%] left-[50%] text-[14px] font-[600] leading-[21px]">
                    Gift Cards
                  </p>
                  <a
                    routerLink="/{{ product.category }}/{{ product.id }}"
                    class="hidden md:flex no-underline text-black h-[24%] items-center self-center w-full">
                    <p
                      class="h-auto md:pt-[5px] !mb-0 overflow-hidden line-clamp-2 text-[12px] font-medium text-center h-[29%] leading-[16px] lg:h-[30%] w-full">
                      {{ product.name }}
                    </p>
                  </a>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-template #noGiftCards>
            <span class="text-[#C40055] hidden md:block text-center font-medium"
              >No Products</span
            >
          </ng-template>
          <div class="w-full hidden lg:flex lg:gap-x-[8px]">
            <ng-container
              *ngFor="let product of $any(pc)['GIFT CARDS']; let i = index"
              [class]="i > 1 ? 'flex' : 'hidden md:flex md:flex-col'">
              <div
                class="lg:basis-[50%] md:flex md:flex-col lg:w-[50%] md:bg-[#ffe7fa] md:p-[6px] justify-around md:rounded-[15px] lg:h-[150px]"
                *ngIf="i > 1 && i < 4">
                <a
                  routerLink="/{{ product.category }}/{{ product.id }}"
                  class="hidden md:block m-auto">
                  <img
                    src="{{product.images?.[0]?.key | appendS3}}"
                    alt=""
                    alt=""
                    class="object-contain w-full h-full md:w-auto md:h-[100px] md:m-auto rounded-[15px] md:rounded-none lg:h-[100px] lg:w-[74px] 2xl:w-[85px]" />
                </a>
                <p
                  class="absolute lg:hidden text-white bottom-[10%] left-[32%] text-[14px] font-[600] leading-[21px]">
                  Gift Cards
                </p>
                <a
                  routerLink="/{{ product.category }}/{{ product.id }}"
                  class="hidden md:flex no-underline text-black h-[24%] items-center self-center w-full">
                  <p
                    class="h-auto md:pt-[5px] lg:w-full overflow-hidden !mb-0 line-clamp-2 text-[12px] font-medium text-center h-[29%] leading-[16px] lg:h-[30%] w-full">
                    {{ product.name }}
                  </p>
                </a>
              </div>
            </ng-container>
          </div>
          <!-- Tablet and Desktop View Ends Here -->
        </div>
        <!-- Gift card ends Here -->

        <!-- Games Starts Here -->
        <div
          class="w-[50%] md:w-auto h-[200px] md:h-[255px] md:basis-full flex md:h-[50%] lg:h-auto md:flex-col lg:w-[50%] relative md:gap-y-[8px] bg-white p-[8px] rounded-[15px]">
          <span
            class="hidden md:block text-center text-black font-medium text-[18px] leading-[27px]">
            <a
              [routerLink]="['/products']"
              [queryParams]="{ category: 'AI TOOLS' }"
              class="no-underline text-black">
              AI Tools</a
            >
          </span>
          <!-- Mobile View Starts Here -->
          <div
            class="w-full block md:hidden md:gap-x-[8px] md:h-[85%] lg:h-auto !max-h-[200px]">
            <a routerLink="/category" [queryParams]="{ category: 'AI TOOLS' }">
              <div
                class="w-full relative h-full grid grid-cols-1 grid-rows-1 md:basis-[50%] rounded-[10px] md:p-[6px] md:rounded-[15px] md:bg-[#dfffdf] lg:h-[150px]">
                <img
                  src="../../assets/images/home/products/netflix.png"
                  alt=""
                  class="object-cover w-full h-full md:w-auto md:h-[100px] md:m-auto rounded-[10px] md:rounded-none lg:h-[70%]" />
                <p
                  class="absolute w-full md:hidden overflow-hidden h-auto !mb-0 text-white bottom-[10%] text-center text-[12px] xs:text-[14px] font-[600] leading-[21px]">
                  AI TOOLS
                </p>
              </div>
            </a>
          </div>
          <!-- Mobile View Ends Here -->

          <!-- Tablet and Desktop View Starts Here -->
          <div
            class="w-full hidden md:flex md:gap-x-[8px] md:h-[85%] lg:h-auto"
            *ngIf="$any(pc)['AI TOOLS']; else noGames">
            <ng-container
              *ngFor="let product of $any(pc)['AI TOOLS']; let i = index"
              [class]="i > 1 ? 'flex' : 'hidden md:!flex md:!flex-col'">
              <div
                class="w-full md:basis-[50%] md:flex md:flex-col rounded-[10px] justify-around md:p-[6px] lg:w-[50%] md:rounded-[15px] md:bg-[#dfffdf] lg:h-[150px]"
                *ngIf="i < 2">
                <a
                  routerLink="/{{ product.category }}/{{ product.id }}"
                  class="hidden md:block m-auto">
                  <img
                    src="{{product.images?.[0]?.key | appendS3}}"
                    alt=""
                    class="object-contain w-full h-full md:w-[120px] md:h-[120px] md:m-auto rounded-[10px] md:rounded-none lg:h-[100px] lg:w-[74px] 2xl:w-[85px]" />
                </a>
                <p
                  class="absolute md:hidden text-white bottom-[10%] left-[50%] text-[14px] font-[600] leading-[21px]">
                  AI TOOLS
                </p>
                <a
                  routerLink="/{{ product.category }}/{{ product.id }}"
                  class="hidden md:flex no-underline text-black h-[24%] items-center self-center w-full">
                  <p
                    class="h-auto md:pt-[5px] overflow-hidden !mb-0 line-clamp-2 text-[12px] font-medium text-center h-[29%] leading-[16px] lg:h-[30%] w-full">
                    {{ product.name }}
                  </p>
                </a>
              </div>
            </ng-container>
          </div>
          <ng-template #noGames class="hidden md:block">
            <span class="text-[#C40055] hidden md:block text-center font-medium"
              >No Products</span
            >
          </ng-template>
          <div class="w-full hidden lg:flex lg:gap-x-[8px]">
            <ng-container
              *ngFor="let product of $any(pc)['AI TOOLS']; let i = index"
              [class]="i > 1 ? 'flex' : 'hidden md:flex md:flex-col'">
              <div
                class="lg:basis-[50%] md:flex md:flex-col md:p-[6px] md:rounded-[15px] lg:w-[50%] justify-around md:bg-[#dfffdf] lg:h-[150px]"
                *ngIf="i > 1 && i < 4">
                <a
                  routerLink="/{{ product.category }}/{{ product.id }}"
                  class="hidden md:block m-auto">
                  <img
                    src="{{product.images?.[0]?.key | appendS3}}"
                    alt=""
                    class="object-contain w-full h-full md:w-auto md:h-[100px] md:m-auto rounded-[15px] md:rounded-none lg:h-[100px] lg:w-[74px] 2xl:w-[85px]" />
                </a>
                <p
                  class="absolute lg:hidden text-white bottom-[10%] left-[32%] text-[14px] font-[600] leading-[21px]">
                  AI TOOLS
                </p>
                <a
                  routerLink="/{{ product.category }}/{{ product.id }}"
                  class="hidden md:flex no-underline text-black h-[24%] items-center self-center w-full">
                  <p
                    class="h-auto md:pt-[5px] overflow-hidden w-full !mb-0 line-clamp-2 text-[12px] font-medium text-center h-[29%] leading-[16px] lg:h-[30%] w-full">
                    {{ product.name }}
                  </p>
                </a>
              </div>
            </ng-container>
          </div>
          <!-- Tablet and Desktop view Ends Here -->
        </div>
        <!-- Games Ends Here -->
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="hidden lg:flex justify-center mt-[20px]">
    <p class="!mb-0 w-1/2 animate-pulse bg-gray-300 h-[20px]"></p>
  </div>
  <div class="grid grid-cols-2 h-[300px] mt-[15px] gap-x-[15px] lg:hidden">
    <div
      *ngFor="let i of [1, 2]"
      class="grid grid-rows-2 animate-pulse bg-gray-200 h-[250px] p-3 gap-[10px]">
      <div class="flex flex-col gap-y-2 p-2 bg-gray-300">
        <p class="animate-pulse bg-gray-400 h-[35%]"></p>
        <span class="animate-pulse bg-gray-400 h-[20px]"></span>
      </div>
      <div class="flex flex-col gap-y-2 p-2 bg-gray-300">
        <p class="animate-pulse bg-gray-400 h-[30%]"></p>
        <span class="animate-pulse bg-gray-400 h-[20px]"></span>
      </div>
    </div>
  </div>
  <div class="hidden lg:grid lg:grid-cols-5 h-[300px] mt-[15px]">
    <div
      *ngFor="let i of [1, 2, 3, 4, 5]"
      class="grid grid-cols-2 animate-pulse bg-gray-300 h-[250px] p-3 gap-[10px]">
      <div
        *ngFor="let j of [1, 2, 3, 4]"
        class="h-[100px] grid grid-cols-2 animate-pulse bg-gray-400 p-3"></div>
    </div>
  </div>
</ng-template>
