import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { RestResponse } from './app.model';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private toastRService: ToastrService
  ) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    //skip some endpoint token
    const skipIntercept = request.headers.has('skip');

    let customized = request.headers;
    if (!skipIntercept && this.authService.token) {
      customized = new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${this.authService.token}`,
      });
    } else {
      customized.delete('skip');
    }

    request = request.clone({
      headers: customized,
      body: request.body,
    });
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.headers.get('content-type')?.startsWith('text/html')) {
            return event;
          }
          const response = event.body as RestResponse;
          if (response.statusCode == 200) {
            event = event.clone({
              body: response.body,
            });
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log('error ', error);
        switch (error.status) {
          case 401: {
            const endPoint = error.url.substring(error.url.lastIndexOf('/'));

            const localEndpoint = window.location.href.replace(
              environment.serverUrl,
              ''
            );
            let msg = 'Invalid Email or password';
            if (endPoint !== '/token') {
              msg = 'Session Expired!! Please login again';
              this.router.navigate(['/login'], {
                queryParams: {
                  redirectUrl: localEndpoint,
                },
              });
              localStorage.removeItem('access_token');
            }
            this.toastRService.error(msg);
            break;
          }

          case 400: {
            const e = error.error;
            if (e.hasOwnProperty('Errors'))
              this.toastRService.error(error.error.Errors[0]);
            else this.toastRService.error(error.error.message);

            break;
          }
          case 409: {
            this.toastRService.error(error.error.message);
            break;
          }
          case 500:
        }
        return throwError(() => new Error(error.error?.message));
      })
    );
  }
}
