<mat-nav-list
  class="sidenav-list h-auto bg-[#c44055] fixed !text-white top-[10px] right-[10px] [&>a]:text-white">
  <a
    mat-list-item
    routerLink="/home"
    class="text-white"
    [mat-dialog-close]="true">
    <mat-icon>home</mat-icon>
    <span>Home</span>
  </a>

  <a
    mat-list-item
    routerLink="/category"
    [queryParams]="{ category: 'SOFTWARE PRODUCTS' }"
    class="!text-white"
    [mat-dialog-close]="true">
    <mat-icon>settings_input_composite</mat-icon>
    <span>Software Products</span>
  </a>
  <a
    mat-list-item
    routerLink="/category"
    [queryParams]="{ category: 'STREAMING SERVICES' }"
    class="!text-white"
    [mat-dialog-close]="true">
    <mat-icon>supervisor_account</mat-icon>
    <span>Streaming Services</span>
  </a>
  <a
    mat-list-item
    routerLink="/category"
    [queryParams]="{ category: 'EDUCATIONAL TOOLS' }"
    class="!text-white"
    [mat-dialog-close]="true">
    <mat-icon>remove_from_queue</mat-icon>
    <span>Educational Tools</span>
  </a>
  <a
    mat-list-item
    routerLink="/category"
    [queryParams]="{ category: 'GIFT CARDS' }"
    class="!text-white"
    [mat-dialog-close]="true">
    <mat-icon>card_giftcard</mat-icon>
    <span>Gift Cards</span>
  </a>
  <a
    mat-list-item
    routerLink="/category"
    [queryParams]="{ category: 'AI TOOLS' }"
    class="!text-white"
    [mat-dialog-close]="true">
    <mat-icon>commute</mat-icon>
    <span>Ai Tools</span>
  </a>
  <a
    mat-list-item
    routerLink="chats"
    class="!text-white"
    [mat-dialog-close]="true">
    <mat-icon>chat</mat-icon>
    <span>Messages</span>
  </a>
  <a
    *ngIf="token == null"
    mat-list-item
    routerLink="login"
    class="!text-white"
    [mat-dialog-close]="true">
    <mat-icon>input</mat-icon>
    <span>Login</span>
  </a>
  <a
    *ngIf="token"
    mat-list-item
    class="!text-white"
    (click)="openDialogWithTemplateRef(myDialog)"
    [mat-dialog-close]="true">
    <mat-icon>account_circle</mat-icon>
    <span>Log Out</span>
  </a>
</mat-nav-list>
<ng-template #myDialog>
  <mat-dialog-content>
    <h1 mat-dialog-title class="text-xl border-b-2 py-1">
      Are you sure you want to Log Out?
    </h1>

    <mat-dialog-actions class="flex flex-row gap-5" align="end">
      <button mat-button mat-dialog-close="false">Cancel</button>
      <button
        (click)="logout()"
        mat-dialog-close="true"
        class="text-red-600"
        mat-button
        [mat-dialog-close]="true"
        cdkFocusInitial>
        LOG OUT
      </button>
    </mat-dialog-actions>
  </mat-dialog-content>
</ng-template>
