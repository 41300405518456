import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';

@Directive({
  selector: '[infiniteScroll]',
  standalone: true,
})
export class MyInfiniteScrollDirective implements AfterViewInit, OnDestroy {
  constructor(private el: ElementRef) { }

  @Output() loadMore = new EventEmitter<void>();
  private options = {
    rootMargin: '0px',
    threshold: 0,
  };

  private mutConfig = {
    childList: true,
  };
  private lastEl!: Element;
  private interObs!: IntersectionObserver;
  private mutObs: MutationObserver = new MutationObserver(mutList => {
    //check if mutList contains target element
    mutList.forEach(m => {
      if (this.el.nativeElement === m.target) {
        if (m.type === 'childList') {
          this.observeLastChild();
        }
      }
    });
  });

  ngAfterViewInit(): void {
    this.mutObs.observe(this.el.nativeElement, this.mutConfig);
    this.interObs = new IntersectionObserver((entries, observer) => {
      //check if last element is intersecting in entries list
      entries.forEach(e => {
        if (e.target === this.lastEl) {
          if (e.isIntersecting) {
            this.loadMore.emit();
            observer.unobserve(this.lastEl);
          }
        }
      });
    }, this.options);

    //first call manually
    this.observeLastChild();
  }

  private observeLastChild() {
    if (this.lastEl) this.interObs.unobserve(this.lastEl);
    const children: HTMLCollection = this.el.nativeElement.children;
    this.lastEl = children[children.length - 1];
    if (this.lastEl) this.interObs.observe(this.lastEl);
  }

  ngOnDestroy(): void {
    this.interObs?.disconnect();
    this.mutObs?.disconnect();
  }
}
