import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '../footer/footer.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [CommonModule,
    FooterComponent,
    RouterModule,
    HeaderComponent
  ],
  templateUrl: './main.component.html',
  styles: [
  ]
})
export class MainComponent {

}
