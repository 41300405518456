import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule, NgIf, NgFor } from '@angular/common';
import { Observable, delay, map, tap } from 'rxjs';
import { Product } from 'src/product.model';
import { ProductService } from 'src/product.service';
import { RouterLink } from '@angular/router';
import { AppendS3Pipe } from '../append-s3.pipe';
import { BrowserModule } from '@angular/platform-browser';

@Component({
  selector: 'app-category-section',
  standalone: true,
  imports: [CommonModule, RouterLink, AppendS3Pipe, NgIf, NgFor],
  templateUrl: './category-section.component.html',
  styles: [],
})
export class CategorySectionComponent implements OnInit {
  productByCategories$: Observable<{}>;
  constructor(
    private productService: ProductService,
    private cdr: ChangeDetectorRef
  ) {}
  categories = [
    'SOFTWARE PRODUCTS',
    'STREAMING SERVICES',
    'EDUCATIONAL TOOLS',
    'GIFT CARDS',
    'AI TOOLS',
  ];

  ngOnInit(): void {
    this.productByCategories$ = this.productService.getProductsByCategories(
      this.categories
    );
    this.cdr.detectChanges();
  }
}
