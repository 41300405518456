<div
  class="flex flex-col py-[15px] bg-gradient-to-r from-[rgba(194,64,85,0.35)] to-[rgba(194,64,85,0.6)] rounded-[10px] mt-[15px]"
  *ngIf="weeklySales | async as product; else loading">
  <ng-container *ngIf="product.length > 0">
    <div
      *ngIf="timeRemaining$ | async as timeRemaining"
      class="flex justify-between items-center md:flex-col md:items-center px-[15px] gap-y-[18px]">
      <p
        class="text-[18px] !mb-0 sm:text-[22px] md:text-[32px] pt-[5px] md:leading-[48px] font-medium leading-[18px]">
        Special offers of this week
      </p>
      <span
        class="block lg:hidden text-[16px] font-medium leading-[24px] w-fit bg-[#c44055] text-white rounded-[10px] px-[10px] py-[5px]"
        >{{ timeRemaining.days }}:{{ timeRemaining.hours }}:{{
          timeRemaining.mins
        }}:{{ timeRemaining.secs }}</span
      >
      <div class="hidden lg:flex gap-x-[10px]">
        <div
          class="flex flex-col items-center w-fit bg-[#c44055] text-white rounded-[10px] p-2 gap-1 px-[8px] py-[3px]">
          <span class="text-[20px] font-medium leading-[24px]">{{
            timeRemaining.days
          }}</span>
          <span class="text-[12px]">Days</span>
        </div>
        <div
          class="flex flex-col items-center w-fit bg-[#c44055] text-white rounded-[10px] p-2 gap-1 px-[8px] py-[3px]">
          <span class="text-[20px] font-medium leading-[24px]">
            {{ timeRemaining.hours }}
          </span>
          <span class="text-[12px]">Hours</span>
        </div>
        <div
          class="flex flex-col w-fit items-center bg-[#c44055] text-white rounded-[10px] p-2 gap-1 px-[8px] py-[3px]">
          <span class="text-[20px] font-medium leading-[24px]">{{
            timeRemaining.mins
          }}</span>
          <span class="text-[12px]">mins</span>
        </div>
        <div
          class="flex flex-col w-fit items-center bg-[#c44055] text-white rounded-[10px] p-2 gap-1 px-[8px] py-[3px]">
          <span class="text-[20px] font-medium leading-[24px]">{{
            timeRemaining.secs
          }}</span>
          <span class="text-[12px]">secs</span>
        </div>
      </div>
    </div>
    <div class="flex pt-4 px-[20px] md:px-[35px]">
      <div class="slider w-full relative">
        <div
          infiniteScroll
          (loadMore)="weeklyLoadMore$.next(weeklyLoadMore$.value + 1)"
          class="slide relative overflow-x-scroll scroll-smooth [&>button]:rounded-[50%] [&>button]:px-[5px] [&>button]:pt-[7px] [&>button]:pb-[4px] flex gap-x-[15px] xs:gap-x-[20px]"
          #slideContainer>
          <!-- <div class="sli flex gap-x-[10px] inline-block"> -->
          <!-- <app-special-product></app-special-product> -->
          <!-- <ng-container > -->
          <!-- class="flex flex-col w-[160px] h-[290px] bg-[#FFFFFF] shadow-md xs:h-[330px] min-w-[160px] xs:w-[225px] xs:min-w-[220px] sm:!h-[345px] sm:!w-[280px] sm:min-w-[230px]" -->
          <div
            class="flex flex-col w-[160px] bg-[#FFFFFF] min-w-[160px] h-[290px] xs:h-[330px] xs:w-[225px] xs:min-w-[220px] sm:!h-[360px] shadow-md sm:min-w-[240px]"
            *ngFor="let specialProd of product; let last = last">
            <div class="relative bg-[#FFFFFF] rounded-t-[10px] h-[60%]">
              <a routerLink="/{{ specialProd.category }}/{{ specialProd.id }}">
                <img
                  src="{{(specialProd.images?.[0]?.key!) | appendS3}}"
                  alt=""
                  class="object-cover w-full h-full rounded-b-[10px]" />
              </a>
              <!-- <button (click)="addToWishList(specialProd)">
                <img
                  src="../../assets/images/home/products/favourite.png"
                  alt=""
                  class="absolute object-contain top-[5%] right-[5%] z-[10]" />
                
              </button> -->
            </div>

            <div
              class="flex flex-col bg-[#FFFFFF] rounded-b-[10px] pt-3 pb-1 px-[4%] h-[40%] justify-around">
              <a
                href=""
                routerLink="/{{ specialProd.category }}/{{ specialProd.id }}"
                class="no-underline">
                <p
                  class="text-[14px] text-black overflow-hidden !mb-0 md:text-[16px] font-medium leading-[18px] md:leading-[21px] line-clamp-1">
                  {{ specialProd.name }}
                </p>
              </a>
              <p
                class="text-[14px] !mb-0 md:text-[16px] font-medium md:font-[600] leading-[18px] md:leading-[19.5px] text-[#5cb85c]">
                NPR
                {{
                  (
                    specialProd.attributes[0].price -
                    (specialProd.discount / 100) *
                      specialProd.attributes[0].price
                  ).toFixed(2)
                }}
              </p>
              <div class="flex justify-between">
                <div class="flex">
                  <p
                    class="text-[12px] !mb-0 md:text-[14px] font-normal leading-[15px] md:leading-[18px] text-[#909296]">
                    Rs
                    <s>{{ specialProd.attributes[0].price.toFixed(2) }}</s>
                  </p>
                  <p
                    class="text-[13px] !mb-0 font-normal leading-[16.5px] md:text-[15px] md:leading-[18px] text-[#f52525]">
                    &nbsp; &nbsp; -{{ specialProd.discount }}%
                  </p>
                </div>
                <button (click)="addToCart(specialProd)">
                  <img
                    src="../../assets/images/home/products/cart.png"
                    alt=""
                    class="object-cover" />
                </button>
              </div>
            </div>
          </div>
          <!-- <div *ngIf="!(ended$ | async)" class="h-full w-30 flex justify-center place-items-center m-auto">
                    <mat-spinner color="primary" class="!h-[70px]"></mat-spinner>
                </div> -->
          <div class="lds-ellipsis top-[115px]" *ngIf="!(ended$ | async)">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <!-- </ng-container> -->
          <!-- </div> -->
        </div>
        <div class="button-area hidden sm:block">
          <button
            class="absolute left-[-5.5%] xs:left-[-3.5%] md:left-[-2.5%] xl:left-[-1.5%] top-[37%] z-[10] bg-white rounded-[50%] pt-[10px] pb-[6px] px-[8px]"
            mat-mini-fab
            (click)="scrollLeft()">
            <mat-icon>arrow_back</mat-icon>
          </button>
          <button
            class="absolute right-[-5.5%] xs:right-[-3.5%] md:right-[-2.5%] xl:right-[-1.5%] top-[37%] shadow-md z-[10] bg-white rounded-[50%] pt-[10px] pb-[6px] px-[8px]"
            mat-mini-fab
            (click)="scrollRight()">
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #loading>
  <div class="flex justify-center mt-3">
    <p class="w-1/2 animate-pulse bg-gray-300 h-[25px]"></p>
  </div>
  <div class="flex justify-center gap-x-3 mt-2">
    <span class="animate-pulse bg-gray-400 w-[50px] h-[50px]"></span>
    <span class="animate-pulse bg-gray-400 w-[50px] h-[50px]"></span>
    <span class="animate-pulse bg-gray-400 w-[50px] h-[50px]"></span>
    <span class="animate-pulse bg-gray-400 w-[50px] h-[50px]"></span>
  </div>
  <div class="grid grid-cols-2 sm:hidden h-[260px] mt-3 gap-x-[15px]">
    <div
      *ngFor="let i of [1, 2]"
      class="flex flex-col animate-pulse bg-gray-300 h-[250px] p-3 gap-[10px]">
      <p class="animate-pulse bg-gray-400 w-full h-[100px]"></p>
      <h1 class="animate-pulse bg-gray-400 w-1/2 h-[20px]"></h1>
      <h2 class="animate-pulse bg-gray-400 w-1/2 h-[20px]"></h2>
      <div class="flex gap-x-2">
        <span class="animate-pulse bg-gray-400 w-1/2 h-[15px]"></span>
        <span class="animate-pulse bg-gray-400 w-1/2 h-[15px]"></span>
      </div>
    </div>
  </div>
  <div
    class="hidden sm:grid sm:grid-cols-3 lg:hidden h-[260px] mt-3 gap-x-[15px]">
    <div
      *ngFor="let i of [1, 2, 3]"
      class="flex flex-col animate-pulse bg-gray-300 h-[250px] p-3 gap-[10px]">
      <p class="animate-pulse bg-gray-400 w-full h-[100px]"></p>
      <h1 class="animate-pulse bg-gray-400 w-1/2 h-[20px]"></h1>
      <h2 class="animate-pulse bg-gray-400 w-1/2 h-[20px]"></h2>
      <div class="flex gap-x-2">
        <span class="animate-pulse bg-gray-400 w-1/2 h-[15px]"></span>
        <span class="animate-pulse bg-gray-400 w-1/2 h-[15px]"></span>
      </div>
    </div>
  </div>
  <div class="hidden lg:grid lg:grid-cols-5 h-[260px] mt-4 gap-x-[15px]">
    <div
      *ngFor="let i of [1, 2, 3, 4, 5]"
      class="flex flex-col animate-pulse bg-gray-300 h-[250px] p-3 gap-[10px]">
      <p class="animate-pulse bg-gray-400 w-full h-[100px]"></p>
      <h1 class="animate-pulse bg-gray-400 w-1/2 h-[20px]"></h1>
      <h2 class="animate-pulse bg-gray-400 w-1/2 h-[20px]"></h2>
      <div class="flex gap-x-2">
        <span class="animate-pulse bg-gray-400 w-1/2 h-[15px]"></span>
        <span class="animate-pulse bg-gray-400 w-1/2 h-[15px]"></span>
      </div>
    </div>
  </div>
</ng-template>
