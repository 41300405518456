import { MatSidenavModule } from '@angular/material/sidenav';
import { Component, Inject, Renderer2 } from '@angular/core';
import {
  RouterLinkActive,
  RouterLinkWithHref,
  RouterOutlet,
} from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';

import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

import { MatListModule } from '@angular/material/list';
import { CommonModule, DOCUMENT } from '@angular/common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, map, shareReplay, tap } from 'rxjs';
import { AppService } from './app.service';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    MatButtonModule,
    MatSidenavModule,
    MatPaginatorModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    RouterLinkWithHref,
    RouterLinkActive,
  ],
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'angular-latest-tailwind-template';
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)

    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  darkMode: Observable<boolean>;

  selectedLang: Observable<'en' | 'np'>;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private appService: AppService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.darkMode = this.appService.isDarkMode.pipe(
      tap(dark =>
        this.renderer.setAttribute(
          this.document.body,
          'class',
          dark ? 'theme-dark' : ''
        )
      )
    );

    this.selectedLang = this.appService.isNepali.pipe(
      map(isNp => (isNp ? 'np' : 'en'))
      // tap(lang => this.translateService.use(lang))
    );
  }

  toggleTheme() {
    this.appService.toggleTheme();
  }

  toggleLang() {
    this.appService.toggleLang();
  }
}
