import Dexie, { Table } from 'dexie';
import { Observable, Subject, defer, startWith, switchMap, tap } from 'rxjs';
import { Attribute } from './product.model';
import { GivenUserInfo } from './order.model';

export interface Carts {
  id?: number;
  images: string[];
  name: string;
  quantity: number;
  // discount: number;
  checked?: boolean;
  givenUserInfo?: GivenUserInfo[];
  attribute: Attribute;
}
export interface Wishlist {
  id?: number;
  images: string[];
  name: string;
  quantity: number;
  // price: number;
  // discount: number;
  checked?: boolean;
  attribute: Attribute;
}
export class AppDB extends Dexie {
  carts!: Table<Carts, number>;
  wishlists!: Table<Wishlist, number>;
  constructor() {
    super('ngdexieliveQuery');
    this.version(3).stores({
      carts: '++id',
      wishlists: '++id',
    });
  }
  addToCart(cart: Carts) {
    return defer(() => db.carts.add(cart)).pipe(
      tap(() => this.cartChange$.next())
    );
  }
  updateGivenUserInfoCart(id: number, givenUserInfo: GivenUserInfo[]) {
    return defer(() =>
      db.carts.update(id, {
        givenUserInfo: givenUserInfo,
      })
    ).pipe(tap(() => this.cartChange$.next()));
  }
  addToWishlist(wishlist: Wishlist) {
    return defer(() => db.wishlists.add(wishlist)).pipe(
      tap(() => this.wishChange$.next())
    );
  }
  cartChange$ = new Subject<void>();
  countAll(): Observable<number> {
    return this.cartChange$.pipe(
      startWith({}),
      switchMap(() => defer(() => db.carts.count()))
    );
  }
  wishChange$ = new Subject<void>();
  countwishlistAll(): Observable<number> {
    return this.wishChange$.pipe(
      startWith({}),
      switchMap(() => defer(() => db.wishlists.count()))
    );
  }
  getCarts(...queries: Queries<Carts>[]) {
    return defer(() => db.carts.toArray());
  }
  getCartById(productId: number) {
    return defer(() => db.carts.where({ id: productId }).toArray());
  }
  removeFromCartById(productId: number) {
    return defer(() => db.carts.delete(productId)).pipe(
      tap(() => this.cartChange$.next())
    );
  }
  getWishlists(...queries: Queries<Wishlist>[]) {
    return defer(() => db.wishlists.toArray());
  }
  getWishlistById(productId: number) {
    return defer(() => db.wishlists.where({ id: productId }).toArray());
  }
  removeFromWishlistById(productId: number) {
    return defer(() => db.wishlists.delete(productId)).pipe(
      tap(() => this.wishChange$.next())
    );
  }
}
export type Queries<T> = { key: keyof T; value: T[keyof T] };
export const db = new AppDB();
