import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private _isDark = localStorage.getItem('theme-dark') === 'true';
  private _isNp = localStorage.getItem('lang-np') === 'true';
  private isDarkMode$ = new BehaviorSubject<boolean>(this._isDark);
  private isNepali$ = new BehaviorSubject<boolean>(this._isNp);
  private showProgressBar$ = new BehaviorSubject<boolean>(false);

  toggleTheme() {
    this._isDark = !this._isDark;
    localStorage.setItem('theme-dark', this._isDark.toString());
    this.isDarkMode$.next(this._isDark);
  }

  toggleLang() {
    this._isNp = !this._isNp;
    localStorage.setItem('lang-np', this._isNp.toString());
    this.isNepali$.next(this._isNp);
  }

  showProgressBar(show: boolean) {
    this.showProgressBar$.next(show);
  }

  get isDarkMode() {
    return this.isDarkMode$.asObservable();
  }

  get isNepali() {
    return this.isNepali$.asObservable();
  }

  get progressBarObs() {
    return this.showProgressBar$.asObservable();
  }
}
