import { CUSTOM_ELEMENTS_SCHEMA, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderImg } from 'src/product.model';
import { AppendS3Pipe } from '../append-s3.pipe';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { SwiperContainer } from 'swiper/element';
import { HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProductService } from 'src/product.service';
@Component({
  selector: 'app-image-carousel',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule,
    AppendS3Pipe,
    MatIconModule,
    RouterLink,
    FormsModule,
    HttpClientModule
  ],
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
})
export class ImageCarouselComponent implements OnInit {
  getImg: Observable<SliderImg[]>;
  @ViewChild("swiper") swiper: ElementRef<SwiperContainer>;
  animationInProgress = false;
  ngOnInit(): void {
    this.getImg = this.productService.getSliderImg();
    this.startAnimation();
  }
  constructor(
    private productService: ProductService
  ) { }

  startAnimation() {
    if (this.animationInProgress) return;
    this.animationInProgress = true;
    setTimeout(() => {
      this.swiper.nativeElement.swiper.slideNext(2000);
      this.animationInProgress = false;
      this.startAnimation();
    }, 5000);
  }
}
