import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MainComponent } from './main/main.component';
import { authGuard } from './auth.guard';
import { RoleGuard } from './role.guard';

export const routes: Routes = [
  {
    path: 'chats',
    canActivate: [authGuard],
    canLoad: [authGuard],
    loadComponent: () =>
      import('./chats/chats.component').then(
        component => component.ChatsComponent
      ),
  },
  {
    path: 'fonepayRedirect',
    loadComponent: () =>
      import('./fone-pay-redirect/fone-pay-redirect.component').then(
        comp => comp.FonePayRedirectComponent
      ),
  },
  {
    path: 'dashboard',
    canActivate: [authGuard],
    canLoad: [authGuard],
    canActivateChild: [RoleGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.routes').then(r => r.routes),
  },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        component: HomeComponent,
        //loadComponent: () => import('./home/home.component').then((component) => component.HomeComponent),
      },
      {
        path: 'cart',
        loadComponent: () =>
          import('./cart/cart.component').then(
            component => component.CartComponent
          ),
        title: 'Carts',
      },
      {
        path: 'wishlist',
        loadComponent: () =>
          import('./wishlist/wishlist.component').then(
            component => component.WishlistComponent
          ),
        title: 'Wishlist',
      },

      {
        path: 'products',
        loadComponent: () =>
          import('./category/category.component').then(
            component => component.CategoryComponent
          ),
      },

      {
        path: 'track-order',
        canLoad: [authGuard],
        loadComponent: () =>
          import('./track-order/track-order.component').then(
            component => component.TrackOrderComponent
          ),
      },
      {
        path: 'track-order/products/:orderId',
        canLoad: [authGuard],
        loadComponent: () =>
          import('./track-order-detail/track-order-detail.component').then(
            component => component.TrackOrderDetailComponent
          ),
      },
      {
        path: 'track-order/payment-history/:orderId',
        canLoad: [authGuard],
        loadComponent: () =>
          import('./payment-details-list/payment-details-list.component').then(
            component => component.PaymentDetailsListComponent
          ),
      },
      {
        path: ':category/:id',
        loadComponent: () =>
          import('./product-detail/product-detail.component').then(
            component => component.ProductDetailComponent
          ),
      },
      {
        path: 'category',
        loadComponent: () =>
          import('./category/category.component').then(
            component => component.CategoryComponent
          ),
        title: 'Filter Products By Category & SubCategory',
      },
      {
        path: 'profile',
        canLoad: [authGuard],
        loadComponent: () =>
          import('./dashboard/profile/profile.component').then(
            component => component.ProfileComponent
          ),
        title: 'Profile - Buy & Binge',
      },
    ],
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./login/login.component').then(
        component => component.LoginComponent
      ),
    title: 'Login - Buy & Binge',
  },
  {
    path: 'signup',
    loadComponent: () =>
      import('./signup/signup.component').then(
        component => component.SignupComponent
      ),
    title: 'Signup - Buy & Binge',
  },
  {
    path: 'forget-password',
    loadComponent: () =>
      import('./forget-password/forget-password.component').then(
        component => component.ForgetPasswordComponent
      ),
    title: 'Forgot Password ? - Buy & Binge',
  },
  {
    path: 'reset-password',
    loadComponent: () =>
      import('./reset-password/reset-password.component').then(
        component => component.ResetPasswordComponent
      ),
    title: 'Reset Password - Buy & Binge',
  },
  {
    path: 'Verify email',
    loadComponent: () =>
      import('./verify-email/verify-email.component').then(
        component => component.VerifyEmailComponent
      ),
    title: 'Verify Email- Buy & Binge',
  },
];
