import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ProductItemsComponent } from '../product-items/product-items.component';
import { PrevDirective } from '../prev.directive';
import { NextDirective } from '../next.directive';
import {
  BehaviorSubject,
  Observable,
  debounceTime,
  delay,
  filter,
  map,
  merge,
  scan,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

import { FilterDTO } from '../app.model';
import { RouterLink, RouterModule } from '@angular/router';
import { SpecialProductComponent } from '../special-product/special-product.component';
import { ProductService } from 'src/product.service';
import { Product, Brand } from 'src/product.model';
import { AppendS3Pipe } from '../append-s3.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CustomSnackBarService } from '../shared/custom.snackbar.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RatingModule } from 'primeng/rating';
import { MyInfiniteScrollDirective } from '../infinite-scroll.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CategorySectionComponent } from '../category-section/category-section.component';
import { SpecialOfferComponent } from '../special-offer/special-offer.component';
import { BestSellerComponent } from '../best-seller/best-seller.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ImageCarouselComponent } from '../image-carousel/image-carousel.component';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  standalone: true,
  providers: [ProductService],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  imports: [
    CommonModule,
    MatIconModule,
    ProductItemsComponent,
    PrevDirective,
    NextDirective,
    RouterLink,
    ImageCarouselComponent,
    SpecialProductComponent,
    AppendS3Pipe,
    MatSnackBarModule,
    RouterModule,
    RatingModule,
    FormsModule,
    ReactiveFormsModule,
    MyInfiniteScrollDirective,
    MatProgressSpinnerModule,
    CategorySectionComponent,
    SpecialOfferComponent,
    BestSellerComponent,
    InfiniteScrollModule,
    NgIf,
  ],
})
export class HomeComponent implements OnInit {
  @Input()
  destroyRef = inject(DestroyRef);
  product: Product;
  product$: Observable<Product[]>;
  quantity: number = 0;
  @ViewChild('slideContainer') slideContainer: any;
  @ViewChild('slide') container: any;
  @ViewChild('suns') sun: any;

  currentIndex: number = 0;
  constructor(
    private cdr: ChangeDetectorRef,
    private productService: ProductService,
    private titleService: Title
  ) {}
  productByCategory$: Observable<Product[]>;
  brand: Observable<Brand[]>;
  totalElements: number = 0;
  ended = new BehaviorSubject<boolean>(false);
  ended$ = this.ended.asObservable();
  loadMoreSubject$ = new BehaviorSubject<number>(0);
  forYouProduct$: Observable<Product[]>;
  isActive: boolean = true;

  ngOnInit(): void {
    this.titleService.setTitle(environment.appTitle);
    this.forYouProduct$ = this.productService.onCategoryChange().pipe(
      switchMap(category => {
        return merge(this.loadMoreSubject$.pipe(delay(500)))
          .pipe(
            debounceTime(500),
            takeUntil(this.ended.pipe(filter(v => !!v))),
            switchMap(pageNumber => {
              const forYou: FilterDTO = {
                pageNumber: pageNumber,
                pageSize: 10,
                sorts: [
                  {
                    key: 'createdTime',
                    direction: 'DESC',
                  },
                ],
              };
              return this.productService.filterData(forYou);
            })
          )
          .pipe(
            tap(page => (this.totalElements = page.totalElements)),
            map(page => page.elements),

            scan((acc, curr) => [...acc, ...curr]),
            tap(news => {
              if (news.length >= this.totalElements) {
                this.ended.next(true);
              }
            })
          );
      })
    );
    this.cdr.detectChanges();

    const categoryProduct: FilterDTO = {
      pageNumber: 0,
      pageSize: 6,
      sorts: [
        {
          key: 'updatedTime',
          direction: 'DESC',
        },
      ],
    };

    this.productByCategory$ = this.productService
      .filterData(categoryProduct)
      .pipe(map(page => page.elements));
  }

  scrollLeft() {
    this.slideContainer.nativeElement.scrollLeft -= 450;
  }

  scrollRight() {
    this.slideContainer.nativeElement.scrollLeft += 450;
  }

  scrollL() {
    this.sun.nativeElement.scrollLeft -= 450;
  }

  scrollR() {
    this.sun.nativeElement.scrollLeft += 450;
  }

  left() {
    this.container.nativeElement.scrollLeft -= 450;
  }

  right() {
    this.container.nativeElement.scrollLeft += 450;
  }

  goToSlide(slideIndex: number): void {
    this.currentIndex = slideIndex;
  }
}
