<div class="px-[15px] bg-all pt-[15px]">
  <div class="flex flex-col md:py-[15px] lg:py-0 max-w-[1366px] mx-auto">
    <div
      class="flex justify-between gap-x-[5px] items-center pb-[15px] md:pb-0 max-h-[92px] md:hidden">
      <button
        [class]="
          router.isActive('/home', false) ? '!hidden' : '!block md:!hidden'
        ">
        <mat-icon (click)="goBack()">keyboard_arrow_left</mat-icon>
      </button>
      <div
        class="text-left block md:hidden text-[14px] font-medium"
        [class]="
          router.isActive('/home', false) ? 'block md:hidden' : 'hidden'
        ">
        <h2 class="text-[#625d69] mb-0">Welcome</h2>
        <p class="mb-0">What are you looking for?</p>
      </div>
      <form
        class="hidden h-[64px] rounded-[16px] m-auto items-center py-[1%] border-[1px] border-solid border-[rgba(31,31,31,0.18)] px-[2%] bg-white justify-between gap-[10px]">
        <input
          #input
          type="text"
          class="bg-transparent placeholder:text-[13px] outline-none basis-[90%]"
          placeholder="Search for products and gifts cards" />
        <button
          type="submit"
          (click)="search(input.value)"
          class="h-[30px] my-auto">
          <mat-icon>search</mat-icon>
        </button>
      </form>
      <div class="flex md:hidden items-center gap-x-[8px]">
        <!-- <a class="h-[30px]" routerLink="/wishlist">
          <img
            src="../../assets/images/product-details/fav.png"
            alt=""
            class="h-[24px]" />
        </a> -->
        <a routerLink="cart" class="h-[30px]">
          <mat-icon
            class="object-cover h-[18px] !text-[#c44055]"
            [matBadge]="count$ | async"
            aria-hidden="false"
            >shopping_cart</mat-icon
          >
        </a>
        <span
          (click)="mobileMenu()"
          class="example-sidenav-content !text-[#c44055]"
          ><mat-icon>reorder</mat-icon>
        </span>
      </div>
    </div>

    <div class="hidden relative p-6">
      <svg
        class="absolute top-[16%] right-[22%] z-[-1]"
        width="240"
        height="240"
        viewBox="0 0 240 240"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="120" cy="120" r="120" fill="url(#paint0_radial_28_66)" />
        <defs>
          <radialGradient
            id="paint0_radial_28_66"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(120 120) rotate(90) scale(120)">
            <stop offset="0.393582" stop-color="white" />
            <stop offset="1" stop-color="white" stop-opacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>

    <div class="pb-[15px] basis-full hidden md:flex">
      <div
        class="basis-[20%] lg:basis-[75%] lg:gap-x-[4px] flex justify-between">
        <div class="basis-full lg:basis-[15%]">
          <a routerLink="home">
            <img
              src="../../assets/images/home/logo.png"
              alt=""
              class="object-contain" />
          </a>
        </div>
        <form
          class="hidden lg:flex lg:basis-[85%] h-[64px] shadow-md rounded-[16px] items-center border-[1px] border-solid border-[rgba(31,31,31,0.18)] px-[2%] bg-white flex justify-between sm:gap-[10px]">
          <input
            #productInput
            type="text"
            class="bg-white placeholder:text-[18px] placeholder:font-normal outline-none w-[90%]"
            placeholder="Search for products and gifts cards..." />
          <button
            type="submit"
            (click)="search(productInput.value)"
            class="h-[30px] my-auto">
            <mat-icon class="!text-[27px]">search</mat-icon>
          </button>
        </form>
      </div>

      <div class="flex basis-[80%] lg:basis-[65%] justify-around">
        <a
          routerLink="track-order"
          class="no-underline flex items-center basis-[25%] justify-end">
          <img
            src="../../assets/images/home/track.png"
            alt=""
            class="object-contain w-[32px] lg:w-[36px] h-[36px]" />
          <span
            class="text-[12px] lg:text-[13px] xl:text-[14px] leading-[18px] font-medium pl-[2%] text-black"
            >Track Order</span
          >
        </a>
        <!-- <div class="flex items-center basis-[25%] justify-end">
          <img src="../../assets/images/home/download.png" alt=""
            class="object-contain w-[32px] lg:w-[36px] h-[36px]" />
          <span class="text-[12px] lg:text-[13px] xl:text-[14px] leading-[18px] font-medium pl-[2%]">Download our
            app</span>
        </div> -->
        <a
          routerLink="/chats"
          class="flex items-center no-underline text-black basis-[25%] justify-end">
          <img
            src="../../assets/images/home/chat.png"
            alt=""
            class="object-contain w-[32px] lg:w-[36px] h-[36px]" />
          <span
            class="text-[12px] lg:text-[13px] xl:text-[14px] leading-[18px] font-medium pl-[5%]"
            >Chat with us</span
          >
        </a>

        <div class="flex items-center gap-4 basis-[25%] justify-end">
          <div
            *ngIf="token == null"
            class="flex flex-row items-center justify-end">
            <a routerLink="/login" class="no-underline text-black">
              <span
                class="text-[12px] lg:text-[13px] xl:text-[14px] leading-[21px] font-medium pl-[2%]"
                >Login
              </span>
            </a>
            <a routerLink="/signup" class="no-underline text-black">
              <span
                class="text-[12px] lg:text-[13px] xl:text-[14px] leading-[21px] font-medium"
                >/Register</span
              >
            </a>
          </div>
          <button
            mat-button
            [matMenuTriggerFor]="menu"
            *ngIf="token && (user$ | async) as user"
            class="flex">
            <ng-container *ngIf="user.profilePic; else noProfilePic">
              <img
                src="{{ user.profilePic | appendS3 }}"
                alt=""
                class="object-contain w-[32px] lg:w-[36px] h-[36px] border-[2px] rounded-[50%]"
                matTooltip="{{ user.username }}" />
            </ng-container>
            <ng-template #noProfilePic>
              <span
                class="bg-[#c44022] px-[8px] py-[5px] text-white rounded-[50%]"
                >{{ getShortName(user.username!) }}</span
              >
            </ng-template>
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <a routerLink="/profile" class="no-underline text-black">
                <span
                  class="text-[12px] lg:text-[13px] xl:text-[14px] font-medium"
                  >Profile</span
                >
              </a>
            </button>
            <button
              mat-menu-item
              *ngIf="token != null"
              (click)="openDialogWithTemplateRef(myDialog)"
              class="flex flex-row gap-1">
              <mat-icon class="block sm:!hidden">logout</mat-icon>
              <a class="no-underline text-black">
                <span
                  class="text-[12px] lg:text-[13px] xl:text-[14px] font-medium"
                  >Log Out</span
                >
              </a>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>

    <form
      class="border-[2px] w-full rounded-[16px] h-[64px] border-solid border-[rgba(31,31,31,0.18)] pt-[5px] pb-[8px] px-[2%] bg-[#e7e7e7] justify-between sm:gap-[10px] pb-[15px] flex lg:hidden">
      <input
        #product
        type="text"
        class="bg-[#e7e7e7] placeholder:text-[13px] w-[93%] placeholder:font-normal outline-none"
        placeholder="Search for products and gifts cards" />
      <button
        type="submit"
        (click)="search(product.value)"
        class="w-[7%] xs:w-[4%] sm:w-auto">
        <mat-icon class="!text-[27px]">search</mat-icon>
      </button>
    </form>
  </div>
  <!-- navigation starts here -->
  <div
    class="hidden md:flex bg-[#c44055] text-white justify-center [&>div>div>span]:text-[14px] [&>div>div>span]:leading-[21px] [&>div>div>span]:font-medium py-[12px] px-[15px] mx-[-15px]">
    <div class="flex max-w-[1366px] w-full">
      <div class="basis-[80%] flex justify-between items-center">
        <div
          class="flex category relative lg:gap-x-[2px] xl:gap-x-[6px]"
          *ngFor="let category of categories">
          <a
            [routerLink]="['/products']"
            [queryParams]="{ category: category.name }"
            class="no-underline text-white">
            <span class="text-[14px] font-medium">{{
              category.name.replace('_', '&nbsp;')
            }}</span>
          </a>
          <mat-icon>expand_more</mat-icon>
          <ul
            class="hidden w-[200px] overflow-hidden rounded-b-[10px] !pl-0 !mb-0 absolute h-auto top-[23px] bg-[#c44055] right-0 z-[99] [&>li>a]:px-[10px] [&>li>a]:py-[5px]"
            *ngIf="category.subCategories">
            <li
              *ngFor="let subCat of category.subCategories; let i = index"
              class="text-[14px] font-medium">
              <a
                class="text-white subcat !w-full !block"
                [routerLink]="['/products']"
                [queryParams]="{
                  category: category.name,
                  subCategory: category.subCategories[i]
                }">
                {{ subCat }}</a
              >
            </li>
          </ul>
          <!-- </ng-container> -->
        </div>
      </div>
      <div class="flex basis-[20%] justify-end gap-x-[8px]">
        <!-- <a routerLink="wishlist">

          <mat-icon class="material-icons object-cover h-[18px] text-white">
            favorite</mat-icon
          >
        </a> -->
        <a routerLink="cart">
          <mat-icon
            class="object-cover h-[18px] text-[#F9E4E7]"
            [matBadge]="count$ | async"
            aria-hidden="false"
            >shopping_cart</mat-icon
          >
        </a>
      </div>
    </div>
  </div>

  <!-- navigation ends here -->
</div>
<ng-template #myDialog>
  <mat-dialog-content>
    <h1
      mat-dialog-title
      class="!text-[14px] !font-[600] !xs:text-xl border-b-2 py-1">
      Are you sure you want to Log Out?
    </h1>
    <mat-dialog-actions class="flex flex-row gap-2" align="end">
      <button
        mat-button
        mat-dialog-close="false"
        class="!bg-blue-400 !text-black !p-2 !rounded-[8px]">
        Cancel
      </button>
      <button
        (click)="logout()"
        mat-dialog-close="true"
        mat-button
        [mat-dialog-close]="true"
        cdkFocusInitial
        class="!bg-[#C44055] !text-white !p-2 !rounded-[8px]">
        LOG OUT
      </button>
    </mat-dialog-actions>
  </mat-dialog-content>
</ng-template>
