<div
  class="px-[15px] bg-all max-w-[1366px] mx-auto overflow-hidden flex flex-col">
  <div
    *ngIf="productByCategory$ | async as product; else sliderLoading"
    class="overflow-y-hidden relative rounded-[15px] bg-gradient-to-r from-[#727473] to-[#db3d56] lg:basis-full h-[150px] xs:h-[240px] lg:h-[280px]">
    <app-image-carousel></app-image-carousel>
  </div>
  <!-- h-[175px] xs:h-[180px] sm:h-[185px] md:h-[195px] lg:hall-->
  <ng-template #sliderLoading>
    <div class="h-[200px] w-full flex gap-x-4 m-0 container !max-w-[1700px]">
      <div
        class="animate-pulse bg-gray-200 flex flex-col gap-y-[20px] w-[50%] p-3">
        <div class="flex justify-end">
          <h1 class="animate-pulse h-[40px] bg-gray-400 w-1/2"></h1>
        </div>
        <p class="animate-pulse h-[40px] bg-gray-400 w-full"></p>
        <div class="flex justify-end">
          <span
            class="animate-pulse h-[40px] bg-gray-400 w-[20%] flex justify-end"></span>
        </div>
      </div>
      <div class="h-[200px] animate-pulse bg-gray-300 w-[50%]"></div>
    </div>
  </ng-template>

  <!-- Category section starts here -->
  <app-category-section></app-category-section>

  <!-- Category section ends here -->

  <!-- special offer section starts here -->
  <app-special-offer></app-special-offer>
  <!-- special offer section ends here -->

  <div #trigger class="h-[365px] xs:h-[410px] sm:h-[457px]">
    @defer (on viewport(trigger)) {
    <app-best-seller></app-best-seller>

    }
  </div>
  <!-- Best sellers starts here -->

  <!-- Best seller ends here -->
</div>
<!-- Chat Support starts here -->
<div
  class="flex lg:hidden justify-around max-w-[1366px] bg-[#e7e7e7] [&>div>img]:h-[38px] px-[5px] py-[3%] [&>div]:h-[80px]">
  <div class="flex flex-col justify-around sm:justify-between">
    <img
      src="../../assets/images/home/products/chat.png"
      alt=""
      class="object-contain" />
    <p
      class="text-[13px] xs:text-[15px] !mb-0 font-medium leading-[18px] sm:text-[17px]">
      24/7 chat support
    </p>
  </div>
  <div class="flex flex-col justify-around sm:justify-between">
    <img
      src="../../assets/images/home/products/cash-on.png"
      alt=""
      class="object-contain" />
    <p
      class="text-[13px] xs:text-[15px] !mb-0 font-medium leading-[18px] sm:text-[17px]">
      cash on delivery
    </p>
  </div>
  <div class="flex flex-col justify-around sm:justify-between">
    <img
      src="../../assets/images/home/products/return.png"
      alt=""
      class="object-contain" />
    <p
      class="text-[13px] xs:text-[15px] !mb-0 font-medium leading-[18px] sm:text-[17px]">
      Easy return
    </p>
  </div>
</div>

<div class="px-[15px] bg-all max-w-[1366px] mx-auto flex flex-col pt-[20px]">
  <!-- Just For you section starts here -->
  <div #trigger>
    @defer (on viewport(trigger)) {

    <ng-container *ngIf="forYouProduct$ | async as prod; else loading" ;>
      <div *ngIf="prod.length > 0">
        <div
          class="flex flex-col bg-transparent md:pb-[20px] rounded-[10px] gap-y-[15px]">
          <p class="text-[16px] !mb-0 md:text-[18px] font-[600] leading-[21px]">
            Just For You
          </p>

          <div
            class="flex justify-center bg-all gap-[8px] xs:gap-[24px] overflow-y-scroll flex-wrap"
            infiniteScroll
            (loadMore)="loadMoreSubject$.next(loadMoreSubject$.value + 1)">
            <app-product-items
              [product]="product"
              *ngFor="let product of prod"></app-product-items>
          </div>
          <div
            *ngIf="!(ended$ | async)"
            class="h-full w-40 flex justify-center place-items-center m-auto">
            <mat-spinner
              color="primary"
              class="!h-[60px] lg:!h-[70px] !w-[60px] lg:!w-[90px]"></mat-spinner>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #loading>
      <div class="flex justify-between">
        <span class="animate-pulse bg-gray-300 h-[25px] w-[15%]"></span>
        <span class="animate-pulse bg-gray-300 h-[25px] w-[15%]"></span>
      </div>
      <div class="grid sm:hidden grid-cols-2 h-[260px] mt-3 gap-x-[15px]">
        <div
          *ngFor="let i of [1, 2]"
          class="flex flex-col animate-pulse bg-gray-300 h-[250px] p-3 gap-[10px]">
          <p class="animate-pulse bg-gray-400 w-full h-[100px]"></p>
          <h1 class="animate-pulse bg-gray-400 w-full h-[20px]"></h1>
          <h2 class="animate-pulse bg-gray-400 w-1/2 h-[20px]"></h2>
          <div class="flex gap-x-2">
            <span class="animate-pulse bg-gray-400 w-1/2 h-[15px]"></span>
            <span class="animate-pulse bg-gray-400 w-1/2 h-[15px]"></span>
          </div>
        </div>
      </div>
      <div
        class="hidden sm:grid lg:hidden sm:grid-cols-3 h-[260px] mt-3 gap-x-[15px]">
        <div
          *ngFor="let i of [1, 2, 3]"
          class="flex flex-col animate-pulse bg-gray-300 h-[250px] p-3 gap-[10px]">
          <p class="animate-pulse bg-gray-400 w-full h-[100px]"></p>
          <h1 class="animate-pulse bg-gray-400 w-full h-[20px]"></h1>
          <h2 class="animate-pulse bg-gray-400 w-1/2 h-[20px]"></h2>
          <div class="flex gap-x-2">
            <span class="animate-pulse bg-gray-400 w-1/2 h-[15px]"></span>
            <span class="animate-pulse bg-gray-400 w-1/2 h-[15px]"></span>
          </div>
        </div>
      </div>
      <div class="hidden lg:grid lg:grid-cols-5 h-[260px] mt-3 gap-x-[15px]">
        <div
          *ngFor="let i of [1, 2, 3, 4, 5]"
          class="flex flex-col animate-pulse bg-gray-300 h-[250px] p-3 gap-[10px]">
          <p class="animate-pulse bg-gray-400 w-full h-[100px]"></p>
          <h1 class="animate-pulse bg-gray-400 w-full h-[20px]"></h1>
          <h2 class="animate-pulse bg-gray-400 w-1/2 h-[20px]"></h2>
          <div class="flex gap-x-2">
            <span class="animate-pulse bg-gray-400 w-1/2 h-[15px]"></span>
            <span class="animate-pulse bg-gray-400 w-1/2 h-[15px]"></span>
          </div>
        </div>
      </div>
    </ng-template>
    }
  </div>

  <!-- Just For You section ends here -->
  <hr
    class="w-full block md:hidden border-[1px] border-solid border-[rgba(0, 0, 0, 0.6)] mt-[2%]" />
</div>
<div
  class="flex flex-col fixed hidden right-0 gap-y-[4px] bottom-[2%] w-[42px]">
  <a routerLink="/chats">
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      class="border-[1px] hover:border-y-[#c44055] border-y-[#F9E4E7] w-[40px] h-[40px] p-[7px] !border-r-0 border-l-[#F9E4E7] hover:border-l-[#c44055]"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.84375 8.25C8.25 8.25 8.59375 8.39062 8.875 8.67188C9.125 8.95312 9.26562 9.3125 9.29688 9.75C9.26562 10.1875 9.10938 10.5469 8.82812 10.8281C8.57812 11.1094 8.25 11.25 7.84375 11.25C7.40625 11.25 7.0625 11.1094 6.8125 10.8281C6.53125 10.5469 6.375 10.1875 6.34375 9.75C6.375 9.3125 6.51562 8.95312 6.76562 8.67188C7.04688 8.39062 7.40625 8.25 7.84375 8.25ZM13.0938 8.25C13.5 8.25 13.8438 8.39062 14.125 8.67188C14.375 8.95312 14.5156 9.3125 14.5469 9.75C14.5156 10.1875 14.375 10.5469 14.125 10.8281C13.8438 11.1094 13.5 11.25 13.0938 11.25C12.6562 11.25 12.2969 11.1094 12.0156 10.8281C11.7344 10.5469 11.5938 10.1875 11.5938 9.75C11.5938 9.3125 11.7344 8.95312 12.0156 8.67188C12.2969 8.39062 12.6562 8.25 13.0938 8.25ZM18.3438 8.25C18.75 8.25 19.0938 8.39062 19.375 8.67188C19.625 8.95312 19.7656 9.3125 19.7969 9.75C19.7656 10.1875 19.6094 10.5469 19.3281 10.8281C19.0781 11.1094 18.75 11.25 18.3438 11.25C17.9062 11.25 17.5625 11.1094 17.3125 10.8281C17.0312 10.5469 16.875 10.1875 16.8438 9.75C16.875 9.3125 17.0156 8.95312 17.2656 8.67188C17.5469 8.39062 17.9062 8.25 18.3438 8.25ZM21.9531 0C22.8281 0.03125 23.5469 0.328125 24.1094 0.890625C24.6406 1.45312 24.9219 2.14063 24.9531 2.95312V16.3594C24.9219 17.2031 24.625 17.9062 24.0625 18.4688C23.5 19.0312 22.7969 19.3125 21.9531 19.3125H15.2031L9.34375 23.7188C9.15625 23.8438 8.96875 23.8594 8.78125 23.7656C8.59375 23.6719 8.48438 23.5 8.45312 23.25V19.3594H3.95312C3.10938 19.3594 2.40625 19.0781 1.84375 18.5156C1.28125 17.9531 0.984375 17.25 0.953125 16.4062V2.95312C0.984375 2.14063 1.28125 1.45312 1.84375 0.890625C2.40625 0.328125 3.10938 0.03125 3.95312 0H21.9531ZM22.75 16.5V3C22.7188 2.53125 22.4688 2.28125 22 2.25H4C3.53125 2.28125 3.28125 2.53125 3.25 3V16.5C3.28125 16.9688 3.53125 17.2188 4 17.25H10.75V20.0625L14.5 17.25H22C22.4688 17.2188 22.7188 16.9688 22.75 16.5Z"
        fill="#c44055"
        fill-opacity="0.6" />
    </svg>
  </a>
  <a routerLink="/home">
    <svg
      width="29"
      height="25"
      viewBox="0 0 29 25"
      class="border-[1px] !border-r-0 border-y-[#F9E4E7] w-[40px] h-[40px] p-[7px] border-l-[#F9E4E7] hover:border-y-[#c44055] hover:border-l-[#c44055]"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.625 11.7812C28.0938 12.25 28.125 12.7812 27.7188 13.375C27.25 13.8438 26.7344 13.875 26.1719 13.4688L25 12.5312V21.25C24.9688 22.3125 24.6094 23.2031 23.9219 23.9219C23.2031 24.6094 22.3125 24.9688 21.25 25H7.75C6.6875 24.9688 5.79688 24.6094 5.07812 23.9219C4.39062 23.2031 4.03125 22.3125 4 21.25V12.5312L2.875 13.4688C2.28125 13.875 1.75 13.8438 1.28125 13.375C0.875 12.7812 0.90625 12.25 1.375 11.7812L13.7969 1.28125C14.2656 0.90625 14.75 0.90625 15.25 1.28125L27.625 11.7812ZM7.75 22.75H10V15.625C10 15.0938 10.1875 14.6562 10.5625 14.3125C10.9062 13.9375 11.3438 13.75 11.875 13.75H17.125C17.6562 13.75 18.0938 13.9375 18.4375 14.3125C18.8125 14.6562 19 15.0938 19 15.625V22.75H21.25C21.6875 22.75 22.0469 22.6094 22.3281 22.3281C22.6094 22.0469 22.75 21.6875 22.75 21.25V10.6094L14.5 3.57812L6.25 10.6094V21.25C6.25 21.6875 6.39062 22.0469 6.67188 22.3281C6.95312 22.6094 7.3125 22.75 7.75 22.75ZM12.25 22.75H16.75V16H12.25V22.75Z"
        fill="#c44055"
        fill-opacity="0.6" />
    </svg>
  </a>
  <a routerLink="/wishlist">
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      class="border-[1px] border-y-[#F9E4E7] !border-r-0 w-[40px] h-[40px] p-[7px] border-l-[#F9E4E7] hover:border-y-[#c44055] hover:border-l-[#c44055]"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.6562 1.95312C24.125 3.29688 24.9062 4.89063 25 6.73438C25.0625 8.57812 24.4375 10.2188 23.125 11.6562L14.0781 21.0312C13.7656 21.3438 13.4062 21.5 13 21.5C12.5938 21.5 12.2344 21.3438 11.9219 21.0312L2.875 11.6562C1.5625 10.25 0.9375 8.60938 1 6.73438C1.0625 4.89063 1.82812 3.29688 3.29688 1.95312C4.48438 0.984375 5.79688 0.5 7.23438 0.5C9.07812 0.53125 10.6875 1.21875 12.0625 2.5625L13 3.5L13.9375 2.5625C15.3125 1.21875 16.9219 0.53125 18.7656 0.5C20.2344 0.46875 21.5312 0.953125 22.6562 1.95312ZM21.5312 10.1094C22.3125 9.26562 22.7188 8.21875 22.75 6.96875C22.7812 5.71875 22.2812 4.60938 21.25 3.64062C20.25 2.85937 19.2188 2.57812 18.1562 2.79688C17.0625 3.01563 16.1875 3.45312 15.5312 4.10938L13 6.73438L10.4688 4.10938C9.78125 3.42187 8.90625 2.98438 7.84375 2.79688C6.78125 2.60938 5.75 2.89062 4.75 3.64062C3.71875 4.60938 3.21875 5.71875 3.25 6.96875C3.28125 8.21875 3.6875 9.26562 4.46875 10.1094L13 18.9219L21.5312 10.1094Z"
        fill="#c44055"
        fill-opacity="0.6" />
    </svg>
  </a>
  <a routerLink="/cart">
    <svg
      width="27"
      height="24"
      viewBox="0 0 27 24"
      class="border-[1px] border-y-[#F9E4E7] !border-r-0 w-[40px] h-[40px] p-[7px] border-l-[#F9E4E7] hover:border-y-[#c44055] hover:border-l-[#c44055]"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 0C5.09375 0.03125 5.46875 0.34375 5.625 0.9375L5.67188 1.5H25.4062C25.9062 1.53125 26.2969 1.73438 26.5781 2.10938C26.8594 2.48438 26.9531 2.92187 26.8594 3.42188L24.3281 12.4219C24.0781 13.1094 23.5938 13.4688 22.875 13.5H8.01562L8.4375 15.75H22.875C23.5625 15.8125 23.9375 16.1875 24 16.875C23.9375 17.5625 23.5625 17.9375 22.875 18H7.45312C6.89062 17.9688 6.53125 17.6719 6.375 17.1094L3.5625 2.25H1.125C0.4375 2.1875 0.0625 1.8125 0 1.125C0.0625 0.4375 0.4375 0.0625 1.125 0H4.5ZM22.3125 11.25L24.4219 3.75H6.14062L7.59375 11.25H22.3125ZM6 21.75C6.03125 21.125 6.25 20.5938 6.65625 20.1562C7.09375 19.75 7.625 19.5312 8.25 19.5C8.875 19.5312 9.40625 19.75 9.84375 20.1562C10.25 20.5938 10.4688 21.125 10.5 21.75C10.4688 22.375 10.25 22.9062 9.84375 23.3438C9.40625 23.75 8.875 23.9688 8.25 24C7.625 23.9688 7.09375 23.75 6.65625 23.3438C6.25 22.9062 6.03125 22.375 6 21.75ZM24 21.75C23.9688 22.375 23.75 22.9062 23.3438 23.3438C22.9062 23.75 22.375 23.9688 21.75 24C21.125 23.9688 20.5938 23.75 20.1562 23.3438C19.75 22.9062 19.5312 22.375 19.5 21.75C19.5312 21.125 19.75 20.5938 20.1562 20.1562C20.5938 19.75 21.125 19.5312 21.75 19.5C22.375 19.5312 22.9062 19.75 23.3438 20.1562C23.75 20.5938 23.9688 21.125 24 21.75Z"
        fill="#c44055"
        fill-opacity="0.6" />
    </svg>
  </a>
  <a routerLink="/track-order">
    <svg
      width="18"
      height="24"
      viewBox="0 0 18 24"
      class="border-[1px] border-y-[#F9E4E7] !border-r-0 w-[40px] h-[40px] p-[7px] border-l-[#F9E4E7] hover:border-y-[#c44055] hover:border-l-[#c44055]"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.75 9C12.7188 10.0625 12.3594 10.9531 11.6719 11.6719C10.9531 12.3594 10.0625 12.7188 9 12.75C7.9375 12.7188 7.04688 12.3594 6.32812 11.6719C5.64062 10.9531 5.28125 10.0625 5.25 9C5.28125 7.9375 5.64062 7.04687 6.32812 6.32812C7.04688 5.64062 7.9375 5.28125 9 5.25C10.0625 5.28125 10.9531 5.64062 11.6719 6.32812C12.3594 7.04687 12.7188 7.9375 12.75 9ZM9 7.5C8.5625 7.5 8.20312 7.64062 7.92188 7.92188C7.64062 8.20312 7.5 8.5625 7.5 9C7.5 9.4375 7.64062 9.79688 7.92188 10.0781C8.20312 10.3594 8.5625 10.5 9 10.5C9.4375 10.5 9.79688 10.3594 10.0781 10.0781C10.3594 9.79688 10.5 9.4375 10.5 9C10.5 8.5625 10.3594 8.20312 10.0781 7.92188C9.79688 7.64062 9.4375 7.5 9 7.5ZM18 9C17.9375 10.4062 17.4375 12.0312 16.5 13.875C15.5312 15.7188 14.4375 17.5 13.2188 19.2188C12 20.9688 10.9688 22.3594 10.125 23.3906C9.8125 23.7656 9.4375 23.9531 9 23.9531C8.5625 23.9531 8.1875 23.7656 7.875 23.3906C7.03125 22.3594 5.98438 20.9688 4.73438 19.2188C3.51562 17.5 2.4375 15.7188 1.5 13.875C0.5625 12.0312 0.0625 10.4062 0 9C0.0625 6.4375 0.9375 4.3125 2.625 2.625C4.3125 0.9375 6.4375 0.0625 9 0C11.5625 0.0625 13.6875 0.9375 15.375 2.625C17.0625 4.3125 17.9375 6.4375 18 9ZM9 2.25C7.09375 2.3125 5.5 2.96875 4.21875 4.21875C2.96875 5.5 2.3125 7.09375 2.25 9C2.25 9.59375 2.48438 10.4844 2.95312 11.6719C3.48438 12.8594 4.15625 14.1094 4.96875 15.4219C5.625 16.5156 6.3125 17.5469 7.03125 18.5156C7.75 19.5156 8.40625 20.4062 9 21.1875C9.59375 20.4062 10.25 19.5312 10.9688 18.5625C11.6875 17.5625 12.375 16.5156 13.0312 15.4219C13.8438 14.1094 14.5156 12.8594 15.0469 11.6719C15.5156 10.4844 15.75 9.59375 15.75 9C15.6875 7.09375 15.0312 5.5 13.7812 4.21875C12.5 2.96875 10.9062 2.3125 9 2.25Z"
        fill="#c44055"
        fill-opacity="0.6" />
    </svg>
  </a>
  <a routerLink="/profile">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      class="border-[1px] border-y-[#F9E4E7] !border-r-0 w-[40px] h-[40px] p-[7px] border-l-[#F9E4E7] hover:border-y-[#c44055] hover:border-l-[#c44055]"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 5.25C13.1562 5.28125 14.125 5.6875 14.9062 6.46875C15.6875 7.25 16.0938 8.21875 16.125 9.375C16.0938 10.5312 15.6875 11.5 14.9062 12.2812C14.125 13.0625 13.1562 13.4688 12 13.5C10.8438 13.4688 9.875 13.0625 9.09375 12.2812C8.3125 11.5 7.90625 10.5312 7.875 9.375C7.90625 8.21875 8.3125 7.25 9.09375 6.46875C9.875 5.6875 10.8438 5.28125 12 5.25ZM12 11.25C12.5312 11.25 12.9688 11.0625 13.3125 10.6875C13.6875 10.3438 13.875 9.90625 13.875 9.375C13.875 8.84375 13.6875 8.40625 13.3125 8.0625C12.9688 7.6875 12.5312 7.5 12 7.5C11.4688 7.5 11.0312 7.6875 10.6875 8.0625C10.3125 8.40625 10.125 8.84375 10.125 9.375C10.125 9.90625 10.3125 10.3438 10.6875 10.6875C11.0312 11.0625 11.4688 11.25 12 11.25ZM12 0C14.25 0.03125 16.2656 0.578125 18.0469 1.64062C19.8594 2.70313 21.2969 4.14062 22.3594 5.95312C23.4219 7.73438 23.9688 9.75 24 12C23.9688 14.25 23.4219 16.2656 22.3594 18.0469C21.2969 19.8594 19.8594 21.2969 18.0469 22.3594C16.2656 23.4219 14.25 23.9688 12 24C9.75 23.9688 7.73438 23.4219 5.95312 22.3594C4.14062 21.2969 2.70312 19.8594 1.64062 18.0469C0.578125 16.2656 0.03125 14.25 0 12C0.03125 9.75 0.578125 7.73438 1.64062 5.95312C2.70312 4.14062 4.14062 2.70313 5.95312 1.64062C7.73438 0.578125 9.75 0.03125 12 0ZM12 21.75C14.2188 21.7188 16.1719 21.0625 17.8594 19.7812C17.4219 19 16.8438 18.3906 16.125 17.9531C15.4062 17.4844 14.5938 17.25 13.6875 17.25H10.3125C9.40625 17.25 8.59375 17.4844 7.875 17.9531C7.15625 18.3906 6.59375 19 6.1875 19.7812C7.84375 21.0625 9.78125 21.7188 12 21.75ZM19.5 18.2344C20.9375 16.4844 21.6875 14.4062 21.75 12C21.6875 9.25 20.7344 6.95312 18.8906 5.10938C17.0469 3.26563 14.75 2.3125 12 2.25C9.25 2.3125 6.95312 3.26563 5.10938 5.10938C3.26562 6.95312 2.3125 9.25 2.25 12C2.3125 14.4062 3.0625 16.4688 4.5 18.1875C5.15625 17.1875 5.98438 16.4062 6.98438 15.8438C7.98438 15.2812 9.09375 15 10.3125 15H13.6875C14.9062 15 16.0156 15.2812 17.0156 15.8438C18.0469 16.4375 18.875 17.2344 19.5 18.2344Z"
        fill="#c44055"
        fill-opacity="0.6" />
    </svg>
  </a>
</div>
