import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BehaviorSubject,
  Observable,
  Subject,
  debounceTime,
  delay,
  filter,
  map,
  scan,
  skipWhile,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { FilterDTO } from '../app.model';
import { Router, RouterLink, RouterModule } from '@angular/router';
import { AppendS3Pipe } from '../append-s3.pipe';
import { ProductService } from 'src/product.service';
import { Product } from 'src/product.model';
import { MatIconModule } from '@angular/material/icon';
import { Carts, Wishlist, db } from 'src/db';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ProductOrderDto } from 'src/order.model';
import { NgForm } from '@angular/forms';
import { CustomSnackBarService } from '../shared/custom.snackbar.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { MyInfiniteScrollDirective } from '../infinite-scroll.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-special-offer',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    AppendS3Pipe,
    RouterModule,
    MatIconModule,
    MatSnackBarModule,
    MyInfiniteScrollDirective,
    MatProgressSpinnerModule,
  ],
  templateUrl: './special-offer.component.html',
  styleUrls: ['./special-offer.component.scss'],
})
export class SpecialOfferComponent implements OnInit {
  weeklyLoadMore$ = new BehaviorSubject<number>(0);
  weeklySales: Observable<Product[]>;
  quantity: number = 0;
  constructor(
    private productService: ProductService,
    private cdr: ChangeDetectorRef,
    private snackbar: CustomSnackBarService,
    private router: Router
  ) {}
  destroyRef = inject(DestroyRef);
  @ViewChild('slideContainer') slideContainer: any;
  timeRemaining$: Observable<{
    days: number;
    hours: number;
    mins: number;
    secs: number;
  }>;
  totalElements: number = 0;
  ended = new BehaviorSubject<boolean>(false);
  ended$ = this.ended.asObservable();

  ngOnInit(): void {
    this.weeklySales = this.weeklyLoadMore$.pipe(
      debounceTime(2000),
      takeUntil(this.ended.pipe(filter(v => !!v))),
      switchMap(pageNumber => {
        const weekly: FilterDTO = {
          pageNumber: pageNumber,
          pageSize: 0,
          sorts: [
            {
              key: 'discount',
              direction: 'DESC',
            },
          ],
        };
        return this.productService.weeklySalesProd(weekly).pipe(
          tap(page => (this.totalElements = page.totalElements)),
          map(page => page.elements)
        );
      }),
      scan((curr, newData) => [...curr, ...newData]),
      tap(products => {
        if (products.length >= this.totalElements) {
          this.ended.next(true);
        }
      })
    );

    this.timeRemaining$ = timer(0, 1000).pipe(
      map(() => {
        const currDate = new Date();
        const currentDay = currDate.getDay() + 1; //js date starts from 0..6 so for convenience
        const remainingDay = 7 - currentDay;
        const remainingHour = 24 - currDate.getHours();
        const remainingMin = 60 - currDate.getMinutes();
        const remainingSecs = 60 - currDate.getSeconds();
        return {
          days: remainingDay,
          hours: remainingHour,
          mins: remainingMin,
          secs: remainingSecs,
        };
      })
    );

    db.getWishlists()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(carts => {
        carts.map(cart => {
          const po: ProductOrderDto = {
            quantity: cart.quantity < 1 ? 1 : cart.quantity,
            productId: cart.id,
            productName: cart.name,
            // discount: cart.productAttribute?.discount,
            productImages: cart.images,
            productAttribute: cart.attribute,
          };
          return po;
        });

        this.cdr.detectChanges();
      });
  }

  scrollLeft() {
    this.slideContainer.nativeElement.scrollLeft -= 450;
  }

  scrollRight() {
    this.slideContainer.nativeElement.scrollLeft += 450;
  }

  addToWishList(product: Product, cartForm?: NgForm) {
    const token = localStorage.getItem('access_token');
    if (!token) {
      this.router.navigate(['/login']);
      this.snackbar.warn('Please login to add to Wishlist');
    } else {
      db.getWishlistById(product.id as number).subscribe(res => {
        if (res?.length > 0) {
          this.snackbar.error(`${product.name} is already in your wishlist`);
        } else {
          const wishlist: Wishlist = {
            images: product.images?.map(res => res.key) ?? [],
            name: product.name,
            quantity: this.quantity,
            // price: product.price,
            // discount: product.discount,
            id: product.id,
            attribute: product.attributes[0],
          };
          db.addToWishlist(wishlist).subscribe(() => {
            this.snackbar.success('Added to wishlist');
          });
        }
      });
    }
  }

  addToCart(product: Product, cartForm?: NgForm) {
    const token = localStorage.getItem('access_token');
    if (!token) {
      this.router.navigate(['/login']);
      this.snackbar.warn('Please login to add to Cart');
    } else {
      db.getCartById(product.id as number).subscribe(res => {
        if (res?.length > 0) {
          this.snackbar.error(`${product.name} is already in cart`);
        } else {
          const cart: Carts = {
            images: product.images.map(res => res.key),
            name: product.name,
            quantity: this.quantity,
            // discount: product.discount,
            id: product.id,
            attribute: product.attributes[0],
          };
          db.addToCart(cart).subscribe(() =>
            this.snackbar.success('Added to cart')
          );
        }
      });
    }
  }
}
