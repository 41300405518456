import { BehaviorSubject, Observable } from 'rxjs';
import { Category, Product, SliderImg } from './product.model';
import { AbstractService } from './app/abstract.service';
import { Injectable } from '@angular/core';
import { FilterDTO, PageDTO } from './app/app.model';
import { HttpHeaders } from '@angular/common/http';
import { environment } from './environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends AbstractService<Product> {
  public search = new BehaviorSubject<string>('');
  override serviceName(): string {
    return 'products';
  }
  private readonly categoryChangeSub$ = new BehaviorSubject<Category>(
    undefined
  );

  public override filterData(
    filter: FilterDTO,
    headers?: HttpHeaders
  ): Observable<PageDTO<Product>> {
    const httpOption = {
      headers: new HttpHeaders({
        skip: 'true',
      }),
    };
    // return super.filterData(filter, headers);
    return this.http.post<PageDTO<Product>>(
      `${this.BASE_URL}/filter`,
      filter,
      httpOption
    );
  }

  public override getById(
    id: number,
    headers?: HttpHeaders
  ): Observable<Product> {
    const httpOption = {
      headers: new HttpHeaders({
        skip: 'true',
      }),
    };
    return this.http.get<Product>(`${this.BASE_URL}/${id}`, httpOption);
  }

  categories = [
    'SOFTWARE PRODUCTS',
    'STREAMING SERVICES',
    'EDUCATIONAL TOOLS',
    'GIFT CARDS',
    'AI TOOLS',
  ];
  onCategoryChange(): Observable<Category> {
    return this.categoryChangeSub$.asObservable();
  }

  getProductsByCategories(categories: string[], headers?: HttpHeaders) {
    const httpOption = {
      headers: new HttpHeaders({
        skip: 'true',
      }),
    };
    return this.http.post<Map<String, Product[]>>(
      `${this.BASE_URL}/byCategories`,
      categories,
      httpOption
    );
  }

  getSliderImg(headers?: HttpHeaders) {
    const httpOption = {
      headers: new HttpHeaders({
        skip: 'true',
      }),
    };
    return this.http.get<SliderImg[]>(
      `${environment.s3Url + environment.s3Object}/config/slider.json`,
      httpOption
    );
  }
  bestSellingProduct(
    filter: FilterDTO,
    headers?: HttpHeaders
  ): Observable<PageDTO<Product>> {
    const httpOption = {
      headers: new HttpHeaders({
        skip: 'true',
      }),
    };
    return this.http.post<PageDTO<Product>>(
      `${this.BASE_URL}/best-selling`,
      filter,
      httpOption
    );
  }

  weeklySalesProd(
    filter: FilterDTO,
    headers?: HttpHeaders
  ): Observable<PageDTO<Product>> {
    const httpOpt = {
      headers: new HttpHeaders({
        skip: 'true',
      }),
    };
    return this.http.post<PageDTO<Product>>(
      `${this.BASE_URL}/weekly-sales`,
      filter,
      httpOpt
    );
  }
}
